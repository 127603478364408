// WARNING: This file is dynamically generated by build_portable_sprite.js.
// DO NOT edit this file directly.
import { SpriteSheet } from './portableSpriteTypes';

const spriteSheet: SpriteSheet = {
  sheetProps: {
    width: 3264,
    height: 3247,
    spriteSize: 96,
    url: '/images/connector_icons/portable_sprite_2025-02-20T20:53:24.908Z.png',
  },
  sprites: {
    '100ms': {
      x: 0,
      y: 0,
      width: 96,
      height: 96,
    },
    '1msg': {
      x: 768,
      y: 3168,
      width: 96,
      height: 34,
    },
    '2chat': {
      x: 96,
      y: 0,
      width: 96,
      height: 96,
    },
    '3scribe': {
      x: 0,
      y: 96,
      width: 96,
      height: 96,
    },
    '4yousee': {
      x: 96,
      y: 96,
      width: 96,
      height: 96,
    },
    '7shifts': {
      x: 192,
      y: 0,
      width: 96,
      height: 96,
    },
    '8x8_contact_center_analytics': {
      x: 192,
      y: 96,
      width: 96,
      height: 96,
    },
    '8x8_work_analytics': {
      x: 0,
      y: 192,
      width: 96,
      height: 96,
    },
    abyssale: {
      x: 96,
      y: 192,
      width: 96,
      height: 96,
    },
    academyocean: {
      x: 3168,
      y: 2542,
      width: 96,
      height: 49,
    },
    accelevents: {
      x: 192,
      y: 192,
      width: 96,
      height: 96,
    },
    activetrail: {
      x: 288,
      y: 0,
      width: 96,
      height: 96,
    },
    acuity_scheduling: {
      x: 288,
      y: 96,
      width: 96,
      height: 96,
    },
    acunetix360: {
      x: 288,
      y: 192,
      width: 96,
      height: 96,
    },
    ad4game: {
      x: 0,
      y: 288,
      width: 96,
      height: 96,
    },
    adaptivework: {
      x: 3168,
      y: 1885,
      width: 96,
      height: 53,
    },
    adbutler: {
      x: 96,
      y: 288,
      width: 96,
      height: 96,
    },
    adikteev: {
      x: 192,
      y: 288,
      width: 96,
      height: 96,
    },
    admithub: {
      x: 288,
      y: 288,
      width: 96,
      height: 96,
    },
    adobe_cja: {
      x: 384,
      y: 0,
      width: 96,
      height: 96,
    },
    adrecord: {
      x: 384,
      y: 96,
      width: 96,
      height: 96,
    },
    adswizz: {
      x: 384,
      y: 192,
      width: 96,
      height: 96,
    },
    adtelligent: {
      x: 384,
      y: 288,
      width: 96,
      height: 96,
    },
    adtraction: {
      x: 0,
      y: 384,
      width: 96,
      height: 96,
    },
    adzuna: {
      x: 96,
      y: 384,
      width: 96,
      height: 96,
    },
    affinda: {
      x: 192,
      y: 384,
      width: 96,
      height: 96,
    },
    afosto: {
      x: 1248,
      y: 3206,
      width: 96,
      height: 21,
    },
    afterlogic: {
      x: 288,
      y: 384,
      width: 96,
      height: 96,
    },
    aftership: {
      x: 3168,
      y: 657,
      width: 96,
      height: 82,
    },
    aftership_returns: {
      x: 384,
      y: 384,
      width: 96,
      height: 96,
    },
    aftership_tracking: {
      x: 3168,
      y: 739,
      width: 96,
      height: 82,
    },
    agencyjoy: {
      x: 480,
      y: 0,
      width: 96,
      height: 96,
    },
    agendor: {
      x: 480,
      y: 96,
      width: 96,
      height: 96,
    },
    agilecrm: {
      x: 480,
      y: 192,
      width: 96,
      height: 96,
    },
    ahrefs: {
      x: 480,
      y: 288,
      width: 96,
      height: 96,
    },
    aimtell: {
      x: 480,
      y: 384,
      width: 96,
      height: 96,
    },
    airmeet: {
      x: 0,
      y: 480,
      width: 96,
      height: 96,
    },
    airops: {
      x: 96,
      y: 480,
      width: 96,
      height: 96,
    },
    airparser: {
      x: 192,
      y: 3206,
      width: 96,
      height: 23,
    },
    airship: {
      x: 192,
      y: 480,
      width: 96,
      height: 96,
    },
    alegra: {
      x: 288,
      y: 480,
      width: 96,
      height: 96,
    },
    algodocs: {
      x: 384,
      y: 480,
      width: 96,
      height: 96,
    },
    all_digital_rewards: {
      x: 480,
      y: 480,
      width: 96,
      height: 96,
    },
    altoviz: {
      x: 576,
      y: 0,
      width: 96,
      height: 96,
    },
    americommerce: {
      x: 3168,
      y: 821,
      width: 96,
      height: 79,
    },
    amilia: {
      x: 576,
      y: 96,
      width: 96,
      height: 96,
    },
    amplifier: {
      x: 576,
      y: 192,
      width: 96,
      height: 96,
    },
    anthropic: {
      x: 576,
      y: 288,
      width: 96,
      height: 96,
    },
    anura: {
      x: 3168,
      y: 2869,
      width: 96,
      height: 42,
    },
    ape_wisdom: {
      x: 576,
      y: 384,
      width: 96,
      height: 96,
    },
    apify: {
      x: 576,
      y: 480,
      width: 96,
      height: 96,
    },
    apollo_io: {
      x: 0,
      y: 576,
      width: 96,
      height: 96,
    },
    apparelmagic: {
      x: 96,
      y: 576,
      width: 96,
      height: 96,
    },
    appbot: {
      x: 192,
      y: 576,
      width: 96,
      height: 96,
    },
    apperio: {
      x: 288,
      y: 576,
      width: 96,
      height: 96,
    },
    appfollow: {
      x: 384,
      y: 576,
      width: 96,
      height: 96,
    },
    applicantstack: {
      x: 3168,
      y: 3148,
      width: 96,
      height: 20,
    },
    appliedsystems_epic: {
      x: 1536,
      y: 3206,
      width: 96,
      height: 20,
    },
    applovin: {
      x: 480,
      y: 576,
      width: 96,
      height: 96,
    },
    applovin_reporting: {
      x: 576,
      y: 576,
      width: 96,
      height: 96,
    },
    appscan: {
      x: 672,
      y: 0,
      width: 96,
      height: 96,
    },
    apptoto: {
      x: 672,
      y: 96,
      width: 96,
      height: 96,
    },
    arbox: {
      x: 672,
      y: 192,
      width: 96,
      height: 96,
    },
    arcadia: {
      x: 672,
      y: 288,
      width: 96,
      height: 96,
    },
    arcadia_utility_cloud: {
      x: 672,
      y: 384,
      width: 96,
      height: 96,
    },
    arcsecond: {
      x: 672,
      y: 480,
      width: 96,
      height: 96,
    },
    argus: {
      x: 384,
      y: 3168,
      width: 96,
      height: 37,
    },
    argyle: {
      x: 672,
      y: 576,
      width: 96,
      height: 96,
    },
    arrivy: {
      x: 0,
      y: 672,
      width: 96,
      height: 96,
    },
    asaas: {
      x: 96,
      y: 672,
      width: 96,
      height: 96,
    },
    askkodiak: {
      x: 192,
      y: 672,
      width: 96,
      height: 96,
    },
    asknicely: {
      x: 288,
      y: 672,
      width: 96,
      height: 96,
    },
    aspireiq: {
      x: 384,
      y: 672,
      width: 96,
      height: 96,
    },
    asset_panda: {
      x: 480,
      y: 672,
      width: 96,
      height: 96,
    },
    atera: {
      x: 576,
      y: 672,
      width: 96,
      height: 96,
    },
    atlas_so: {
      x: 672,
      y: 672,
      width: 96,
      height: 96,
    },
    atsanywhere: {
      x: 2688,
      y: 3206,
      width: 96,
      height: 18,
    },
    audienceful: {
      x: 768,
      y: 0,
      width: 96,
      height: 96,
    },
    audiostack: {
      x: 2976,
      y: 3168,
      width: 96,
      height: 24,
    },
    autodesk_construction_cloud: {
      x: 768,
      y: 96,
      width: 96,
      height: 96,
    },
    autopilot: {
      x: 768,
      y: 192,
      width: 96,
      height: 96,
    },
    avatax: {
      x: 768,
      y: 288,
      width: 96,
      height: 96,
    },
    avaza: {
      x: 768,
      y: 384,
      width: 96,
      height: 96,
    },
    avochato: {
      x: 768,
      y: 480,
      width: 96,
      height: 96,
    },
    avosms: {
      x: 768,
      y: 576,
      width: 96,
      height: 96,
    },
    awardwallet: {
      x: 768,
      y: 672,
      width: 96,
      height: 96,
    },
    aweber: {
      x: 1632,
      y: 3206,
      width: 96,
      height: 20,
    },
    axleapi: {
      x: 0,
      y: 768,
      width: 96,
      height: 96,
    },
    axonaut: {
      x: 96,
      y: 768,
      width: 96,
      height: 96,
    },
    ayanza: {
      x: 192,
      y: 768,
      width: 96,
      height: 96,
    },
    ayrshare: {
      x: 288,
      y: 768,
      width: 96,
      height: 96,
    },
    bannerbite: {
      x: 384,
      y: 768,
      width: 96,
      height: 96,
    },
    baremetrics: {
      x: 480,
      y: 768,
      width: 96,
      height: 96,
    },
    basin: {
      x: 576,
      y: 768,
      width: 96,
      height: 96,
    },
    basis: {
      x: 672,
      y: 768,
      width: 96,
      height: 96,
    },
    batchdialer: {
      x: 768,
      y: 768,
      width: 96,
      height: 96,
    },
    beaglesecurity: {
      x: 864,
      y: 0,
      width: 96,
      height: 96,
    },
    beamer: {
      x: 864,
      y: 96,
      width: 96,
      height: 96,
    },
    beehiiv: {
      x: 3168,
      y: 1938,
      width: 96,
      height: 53,
    },
    beekeeper: {
      x: 864,
      y: 192,
      width: 96,
      height: 96,
    },
    beeline: {
      x: 864,
      y: 288,
      width: 96,
      height: 96,
    },
    beeminder: {
      x: 864,
      y: 384,
      width: 96,
      height: 96,
    },
    belong: {
      x: 864,
      y: 480,
      width: 96,
      height: 96,
    },
    belvo: {
      x: 864,
      y: 576,
      width: 96,
      height: 96,
    },
    benchmark_email: {
      x: 864,
      y: 672,
      width: 96,
      height: 96,
    },
    betterproposals: {
      x: 2688,
      y: 3168,
      width: 96,
      height: 25,
    },
    betterstack: {
      x: 864,
      y: 768,
      width: 96,
      height: 96,
    },
    betteruptime: {
      x: 0,
      y: 864,
      width: 96,
      height: 96,
    },
    bevy: {
      x: 96,
      y: 864,
      width: 96,
      height: 96,
    },
    bexio: {
      x: 192,
      y: 864,
      width: 96,
      height: 96,
    },
    bidsketch: {
      x: 288,
      y: 864,
      width: 96,
      height: 96,
    },
    bigmailer: {
      x: 384,
      y: 864,
      width: 96,
      height: 96,
    },
    bigoven: {
      x: 480,
      y: 864,
      width: 96,
      height: 96,
    },
    bilflo: {
      x: 576,
      y: 864,
      width: 96,
      height: 96,
    },
    bill_com: {
      x: 672,
      y: 864,
      width: 96,
      height: 96,
    },
    billit: {
      x: 768,
      y: 864,
      width: 96,
      height: 96,
    },
    billplz: {
      x: 864,
      y: 864,
      width: 96,
      height: 96,
    },
    billsby: {
      x: 960,
      y: 0,
      width: 96,
      height: 96,
    },
    bitballoon: {
      x: 960,
      y: 96,
      width: 96,
      height: 96,
    },
    bitbucket_cloud: {
      x: 960,
      y: 192,
      width: 96,
      height: 96,
    },
    blackduck: {
      x: 960,
      y: 288,
      width: 96,
      height: 96,
    },
    bland_ai: {
      x: 2304,
      y: 3168,
      width: 96,
      height: 26,
    },
    blink: {
      x: 960,
      y: 384,
      width: 96,
      height: 96,
    },
    blockchain: {
      x: 960,
      y: 480,
      width: 96,
      height: 96,
    },
    bloom_credit: {
      x: 960,
      y: 576,
      width: 96,
      height: 96,
    },
    bloomerang: {
      x: 960,
      y: 672,
      width: 96,
      height: 96,
    },
    bloomreach: {
      x: 960,
      y: 768,
      width: 96,
      height: 96,
    },
    bls: {
      x: 960,
      y: 864,
      width: 96,
      height: 96,
    },
    blueink: {
      x: 0,
      y: 960,
      width: 96,
      height: 96,
    },
    blueshift: {
      x: 96,
      y: 960,
      width: 96,
      height: 96,
    },
    bluesnap: {
      x: 192,
      y: 960,
      width: 96,
      height: 96,
    },
    bolddesk: {
      x: 288,
      y: 960,
      width: 96,
      height: 96,
    },
    boloo: {
      x: 384,
      y: 960,
      width: 96,
      height: 96,
    },
    bolt: {
      x: 480,
      y: 960,
      width: 96,
      height: 96,
    },
    bonusly: {
      x: 576,
      y: 960,
      width: 96,
      height: 96,
    },
    booking_manager: {
      x: 672,
      y: 960,
      width: 96,
      height: 96,
    },
    bookingmood: {
      x: 768,
      y: 960,
      width: 96,
      height: 96,
    },
    boomtown: {
      x: 864,
      y: 960,
      width: 96,
      height: 96,
    },
    booqable: {
      x: 384,
      y: 3230,
      width: 96,
      height: 15,
    },
    bossdesk: {
      x: 960,
      y: 960,
      width: 96,
      height: 96,
    },
    bot9: {
      x: 1056,
      y: 0,
      width: 96,
      height: 96,
    },
    botpenguin: {
      x: 1056,
      y: 96,
      width: 96,
      height: 96,
    },
    bounsel: {
      x: 1056,
      y: 192,
      width: 96,
      height: 96,
    },
    boxhero: {
      x: 1056,
      y: 288,
      width: 96,
      height: 96,
    },
    brandmentions: {
      x: 1056,
      y: 384,
      width: 96,
      height: 96,
    },
    brandwatch: {
      x: 1056,
      y: 480,
      width: 96,
      height: 96,
    },
    brankas: {
      x: 1056,
      y: 576,
      width: 96,
      height: 96,
    },
    bravenewcoin: {
      x: 1056,
      y: 672,
      width: 96,
      height: 96,
    },
    breezy_hr: {
      x: 1056,
      y: 768,
      width: 96,
      height: 96,
    },
    bridge: {
      x: 3168,
      y: 2993,
      width: 96,
      height: 39,
    },
    brightmove: {
      x: 1056,
      y: 864,
      width: 96,
      height: 96,
    },
    brightpattern: {
      x: 1056,
      y: 960,
      width: 96,
      height: 96,
    },
    brilliant: {
      x: 0,
      y: 1056,
      width: 96,
      height: 96,
    },
    brivo: {
      x: 96,
      y: 1056,
      width: 96,
      height: 96,
    },
    broadage: {
      x: 192,
      y: 1056,
      width: 96,
      height: 96,
    },
    brokermint: {
      x: 288,
      y: 1056,
      width: 96,
      height: 96,
    },
    browse_ai: {
      x: 384,
      y: 1056,
      width: 96,
      height: 96,
    },
    browserbear: {
      x: 480,
      y: 1056,
      width: 96,
      height: 96,
    },
    browserhub: {
      x: 576,
      y: 1056,
      width: 96,
      height: 96,
    },
    browserless: {
      x: 672,
      y: 1056,
      width: 96,
      height: 96,
    },
    bugcrowd: {
      x: 768,
      y: 1056,
      width: 96,
      height: 96,
    },
    bugherd: {
      x: 864,
      y: 1056,
      width: 96,
      height: 96,
    },
    bugsnag: {
      x: 960,
      y: 1056,
      width: 96,
      height: 96,
    },
    builder_io: {
      x: 1056,
      y: 1056,
      width: 96,
      height: 96,
    },
    bulksms: {
      x: 1152,
      y: 0,
      width: 96,
      height: 96,
    },
    bunnydoc: {
      x: 1152,
      y: 96,
      width: 96,
      height: 96,
    },
    buttondown: {
      x: 1152,
      y: 192,
      width: 96,
      height: 96,
    },
    buy_me_a_coffee: {
      x: 3168,
      y: 1314,
      width: 66,
      height: 96,
    },
    bytenite: {
      x: 1152,
      y: 288,
      width: 96,
      height: 96,
    },
    cadmium_edharvester: {
      x: 1152,
      y: 384,
      width: 96,
      height: 96,
    },
    cakemail: {
      x: 2112,
      y: 3206,
      width: 96,
      height: 19,
    },
    cal: {
      x: 1152,
      y: 480,
      width: 96,
      height: 96,
    },
    calendarific: {
      x: 1152,
      y: 576,
      width: 96,
      height: 96,
    },
    callfire: {
      x: 1152,
      y: 672,
      width: 96,
      height: 96,
    },
    callgear: {
      x: 1152,
      y: 768,
      width: 96,
      height: 96,
    },
    callpage: {
      x: 1152,
      y: 864,
      width: 96,
      height: 96,
    },
    campaigner: {
      x: 1152,
      y: 960,
      width: 96,
      height: 96,
    },
    candid: {
      x: 1152,
      y: 1056,
      width: 96,
      height: 96,
    },
    canix: {
      x: 0,
      y: 1152,
      width: 96,
      height: 96,
    },
    capsulecrm: {
      x: 288,
      y: 3206,
      width: 96,
      height: 23,
    },
    capsulink: {
      x: 96,
      y: 1152,
      width: 96,
      height: 96,
    },
    captain_data: {
      x: 192,
      y: 1152,
      width: 96,
      height: 96,
    },
    captionhub: {
      x: 288,
      y: 1152,
      width: 96,
      height: 96,
    },
    captivateiq: {
      x: 384,
      y: 1152,
      width: 96,
      height: 96,
    },
    cardly: {
      x: 2208,
      y: 3206,
      width: 96,
      height: 19,
    },
    cart: {
      x: 480,
      y: 1152,
      width: 96,
      height: 96,
    },
    cartoncloud: {
      x: 576,
      y: 1152,
      width: 96,
      height: 96,
    },
    cartrack: {
      x: 672,
      y: 1152,
      width: 96,
      height: 96,
    },
    cartrover: {
      x: 768,
      y: 1152,
      width: 96,
      height: 96,
    },
    cashboard: {
      x: 864,
      y: 1152,
      width: 96,
      height: 96,
    },
    casting42: {
      x: 960,
      y: 1152,
      width: 96,
      height: 96,
    },
    castmagic: {
      x: 1056,
      y: 1152,
      width: 96,
      height: 96,
    },
    cats: {
      x: 1152,
      y: 1152,
      width: 96,
      height: 96,
    },
    cbre_ea: {
      x: 1248,
      y: 0,
      width: 96,
      height: 96,
    },
    celigo: {
      x: 1248,
      y: 96,
      width: 96,
      height: 96,
    },
    certify: {
      x: 1248,
      y: 192,
      width: 96,
      height: 96,
    },
    channable: {
      x: 1248,
      y: 288,
      width: 96,
      height: 96,
    },
    channelape: {
      x: 1248,
      y: 384,
      width: 96,
      height: 96,
    },
    chaport: {
      x: 1248,
      y: 480,
      width: 96,
      height: 96,
    },
    chargeback: {
      x: 1248,
      y: 576,
      width: 96,
      height: 96,
    },
    chargeblast: {
      x: 2400,
      y: 3168,
      width: 96,
      height: 26,
    },
    chargeover: {
      x: 864,
      y: 3230,
      width: 96,
      height: 11,
    },
    charthop: {
      x: 1248,
      y: 672,
      width: 96,
      height: 96,
    },
    chatbot: {
      x: 1248,
      y: 768,
      width: 96,
      height: 96,
    },
    chatfly: {
      x: 1248,
      y: 864,
      width: 96,
      height: 96,
    },
    chatham_financial: {
      x: 1248,
      y: 960,
      width: 96,
      height: 96,
    },
    chattermill: {
      x: 1248,
      y: 1056,
      width: 96,
      height: 96,
    },
    chatwoot: {
      x: 1248,
      y: 1152,
      width: 96,
      height: 96,
    },
    checkbook: {
      x: 0,
      y: 1248,
      width: 96,
      height: 96,
    },
    checkout_champ: {
      x: 96,
      y: 1248,
      width: 96,
      height: 96,
    },
    cheddar: {
      x: 192,
      y: 1248,
      width: 96,
      height: 96,
    },
    chilipiper: {
      x: 288,
      y: 1248,
      width: 96,
      height: 96,
    },
    cincopa: {
      x: 384,
      y: 1248,
      width: 96,
      height: 96,
    },
    circle_so: {
      x: 480,
      y: 1248,
      width: 96,
      height: 96,
    },
    citrusad: {
      x: 576,
      y: 1248,
      width: 96,
      height: 96,
    },
    claid_ai: {
      x: 672,
      y: 1248,
      width: 96,
      height: 96,
    },
    claimcenter: {
      x: 2976,
      y: 3206,
      width: 96,
      height: 17,
    },
    classy: {
      x: 768,
      y: 1248,
      width: 96,
      height: 96,
    },
    clearbit: {
      x: 864,
      y: 1248,
      width: 96,
      height: 96,
    },
    clerk: {
      x: 960,
      y: 1248,
      width: 96,
      height: 96,
    },
    clevertap: {
      x: 1056,
      y: 1248,
      width: 96,
      height: 96,
    },
    click2mail: {
      x: 1152,
      y: 1248,
      width: 96,
      height: 96,
    },
    clickfunnels: {
      x: 1248,
      y: 1248,
      width: 96,
      height: 96,
    },
    clicksend: {
      x: 1344,
      y: 0,
      width: 96,
      height: 96,
    },
    clicktime: {
      x: 1344,
      y: 96,
      width: 96,
      height: 96,
    },
    cliengo: {
      x: 1344,
      y: 192,
      width: 96,
      height: 96,
    },
    client_success: {
      x: 1344,
      y: 288,
      width: 96,
      height: 96,
    },
    clientify: {
      x: 1344,
      y: 384,
      width: 96,
      height: 96,
    },
    clientjoy: {
      x: 1344,
      y: 480,
      width: 96,
      height: 96,
    },
    cloud_assess: {
      x: 1344,
      y: 576,
      width: 96,
      height: 96,
    },
    cloudcart: {
      x: 1344,
      y: 672,
      width: 96,
      height: 96,
    },
    cloudconvert: {
      x: 1344,
      y: 768,
      width: 96,
      height: 96,
    },
    cloudflare: {
      x: 960,
      y: 3168,
      width: 96,
      height: 32,
    },
    cloudinary: {
      x: 2304,
      y: 3206,
      width: 96,
      height: 19,
    },
    cloudlayer: {
      x: 1344,
      y: 864,
      width: 96,
      height: 96,
    },
    cloutly: {
      x: 1344,
      y: 960,
      width: 96,
      height: 96,
    },
    cloze: {
      x: 1344,
      y: 1056,
      width: 96,
      height: 96,
    },
    cms_gov: {
      x: 1344,
      y: 1152,
      width: 96,
      height: 96,
    },
    coalesce: {
      x: 1344,
      y: 1248,
      width: 96,
      height: 96,
    },
    coda: {
      x: 0,
      y: 1344,
      width: 96,
      height: 96,
    },
    codacy: {
      x: 96,
      y: 1344,
      width: 96,
      height: 96,
    },
    cody: {
      x: 192,
      y: 1344,
      width: 96,
      height: 96,
    },
    coingecko: {
      x: 288,
      y: 1344,
      width: 96,
      height: 96,
    },
    coinmarketcal: {
      x: 384,
      y: 1344,
      width: 96,
      height: 96,
    },
    coinmarketcap: {
      x: 480,
      y: 1344,
      width: 96,
      height: 96,
    },
    collect: {
      x: 576,
      y: 1344,
      width: 96,
      height: 96,
    },
    cometchat: {
      x: 672,
      y: 1344,
      width: 96,
      height: 96,
    },
    cometly: {
      x: 768,
      y: 1344,
      width: 96,
      height: 96,
    },
    commcare: {
      x: 864,
      y: 1344,
      width: 96,
      height: 96,
    },
    commerce7: {
      x: 960,
      y: 1344,
      width: 96,
      height: 96,
    },
    commerce_layer: {
      x: 1056,
      y: 1344,
      width: 96,
      height: 96,
    },
    commissionfactory: {
      x: 1152,
      y: 1344,
      width: 96,
      height: 96,
    },
    common_room: {
      x: 1248,
      y: 1344,
      width: 96,
      height: 96,
    },
    commonpaper: {
      x: 1344,
      y: 1344,
      width: 96,
      height: 96,
    },
    commpeak: {
      x: 1440,
      y: 0,
      width: 96,
      height: 96,
    },
    companycam: {
      x: 576,
      y: 3168,
      width: 96,
      height: 36,
    },
    concord_walmart: {
      x: 3168,
      y: 3110,
      width: 96,
      height: 38,
    },
    connectandsell: {
      x: 1440,
      y: 96,
      width: 96,
      height: 96,
    },
    contentful: {
      x: 1440,
      y: 192,
      width: 96,
      height: 96,
    },
    contentgroove: {
      x: 1440,
      y: 288,
      width: 96,
      height: 96,
    },
    contentking: {
      x: 1440,
      y: 384,
      width: 96,
      height: 96,
    },
    contentstack_delivery: {
      x: 1440,
      y: 480,
      width: 96,
      height: 96,
    },
    contentstack_management: {
      x: 1440,
      y: 576,
      width: 96,
      height: 96,
    },
    convertio: {
      x: 1440,
      y: 672,
      width: 96,
      height: 96,
    },
    convertkit: {
      x: 1440,
      y: 768,
      width: 96,
      height: 96,
    },
    copilot: {
      x: 1440,
      y: 864,
      width: 96,
      height: 96,
    },
    copperx: {
      x: 1440,
      y: 960,
      width: 96,
      height: 96,
    },
    copy_ai: {
      x: 1440,
      y: 1056,
      width: 96,
      height: 96,
    },
    cordial: {
      x: 1440,
      y: 1152,
      width: 96,
      height: 96,
    },
    corsizio: {
      x: 1440,
      y: 1248,
      width: 96,
      height: 96,
    },
    courier: {
      x: 1440,
      y: 1344,
      width: 96,
      height: 96,
    },
    covalent: {
      x: 0,
      y: 1440,
      width: 96,
      height: 96,
    },
    cradl: {
      x: 96,
      y: 1440,
      width: 96,
      height: 96,
    },
    craftboxx: {
      x: 192,
      y: 1440,
      width: 96,
      height: 96,
    },
    craftnet: {
      x: 288,
      y: 1440,
      width: 96,
      height: 96,
    },
    creatomate: {
      x: 384,
      y: 1440,
      width: 96,
      height: 96,
    },
    credly: {
      x: 480,
      y: 1440,
      width: 96,
      height: 96,
    },
    crelate: {
      x: 576,
      y: 1440,
      width: 96,
      height: 96,
    },
    criteo_marketing: {
      x: 672,
      y: 1440,
      width: 96,
      height: 96,
    },
    cronofy: {
      x: 768,
      y: 1440,
      width: 96,
      height: 96,
    },
    crowd: {
      x: 864,
      y: 1440,
      width: 96,
      height: 96,
    },
    crowdin: {
      x: 960,
      y: 1440,
      width: 96,
      height: 96,
    },
    crowdtangle: {
      x: 1056,
      y: 1440,
      width: 96,
      height: 96,
    },
    crunchbase: {
      x: 1152,
      y: 1440,
      width: 96,
      height: 96,
    },
    cs_cart: {
      x: 1248,
      y: 1440,
      width: 96,
      height: 96,
    },
    curator_io: {
      x: 1344,
      y: 1440,
      width: 96,
      height: 96,
    },
    custify: {
      x: 1440,
      y: 1440,
      width: 96,
      height: 96,
    },
    customergauge: {
      x: 1536,
      y: 0,
      width: 96,
      height: 96,
    },
    cyberimpact: {
      x: 1536,
      y: 96,
      width: 96,
      height: 96,
    },
    daftra: {
      x: 1536,
      y: 192,
      width: 96,
      height: 96,
    },
    dashdoc: {
      x: 1536,
      y: 288,
      width: 96,
      height: 96,
    },
    data_world: {
      x: 1536,
      y: 384,
      width: 96,
      height: 96,
    },
    datadive: {
      x: 1536,
      y: 480,
      width: 96,
      height: 96,
    },
    datanyze: {
      x: 1536,
      y: 576,
      width: 96,
      height: 96,
    },
    dealmachine: {
      x: 1536,
      y: 672,
      width: 96,
      height: 96,
    },
    debtpaypro: {
      x: 1536,
      y: 768,
      width: 96,
      height: 96,
    },
    deel: {
      x: 1536,
      y: 864,
      width: 96,
      height: 96,
    },
    deeptalk_ai: {
      x: 1536,
      y: 960,
      width: 96,
      height: 96,
    },
    degreed: {
      x: 1536,
      y: 1056,
      width: 96,
      height: 96,
    },
    delta_projects: {
      x: 1536,
      y: 1152,
      width: 96,
      height: 96,
    },
    demio: {
      x: 1536,
      y: 1248,
      width: 96,
      height: 96,
    },
    denim: {
      x: 1536,
      y: 1344,
      width: 96,
      height: 96,
    },
    design_pickle: {
      x: 1536,
      y: 1440,
      width: 96,
      height: 96,
    },
    determ: {
      x: 0,
      y: 1536,
      width: 96,
      height: 96,
    },
    devcycle: {
      x: 1344,
      y: 3206,
      width: 96,
      height: 21,
    },
    developerhub: {
      x: 96,
      y: 1536,
      width: 96,
      height: 96,
    },
    devskiller: {
      x: 192,
      y: 1536,
      width: 96,
      height: 96,
    },
    dexatel: {
      x: 288,
      y: 1536,
      width: 96,
      height: 96,
    },
    dexscreener: {
      x: 384,
      y: 1536,
      width: 96,
      height: 96,
    },
    dext: {
      x: 480,
      y: 1536,
      width: 96,
      height: 96,
    },
    dialog_insight: {
      x: 576,
      y: 1536,
      width: 96,
      height: 96,
    },
    didacte: {
      x: 2496,
      y: 3168,
      width: 96,
      height: 26,
    },
    diese: {
      x: 3072,
      y: 3168,
      width: 96,
      height: 24,
    },
    digify: {
      x: 672,
      y: 1536,
      width: 96,
      height: 96,
    },
    digital_turbine: {
      x: 768,
      y: 1536,
      width: 96,
      height: 96,
    },
    digitalchalk: {
      x: 864,
      y: 1536,
      width: 96,
      height: 96,
    },
    digitalocean: {
      x: 960,
      y: 1536,
      width: 96,
      height: 96,
    },
    dingconnect: {
      x: 1056,
      y: 1536,
      width: 96,
      height: 96,
    },
    disco: {
      x: 1344,
      y: 3168,
      width: 96,
      height: 29,
    },
    discord_bot: {
      x: 1152,
      y: 1536,
      width: 96,
      height: 96,
    },
    discourse: {
      x: 1248,
      y: 1536,
      width: 96,
      height: 96,
    },
    disqo: {
      x: 3168,
      y: 3168,
      width: 96,
      height: 24,
    },
    docdown: {
      x: 1344,
      y: 1536,
      width: 96,
      height: 96,
    },
    docparser: {
      x: 1440,
      y: 1536,
      width: 96,
      height: 96,
    },
    docsbot: {
      x: 1536,
      y: 1536,
      width: 96,
      height: 96,
    },
    docsumo: {
      x: 1632,
      y: 0,
      width: 96,
      height: 96,
    },
    documint: {
      x: 1632,
      y: 96,
      width: 96,
      height: 96,
    },
    documo: {
      x: 1632,
      y: 192,
      width: 96,
      height: 96,
    },
    docupilot: {
      x: 1632,
      y: 288,
      width: 96,
      height: 96,
    },
    doit: {
      x: 1632,
      y: 384,
      width: 96,
      height: 96,
    },
    dola_me: {
      x: 1632,
      y: 480,
      width: 96,
      height: 96,
    },
    donately: {
      x: 1632,
      y: 576,
      width: 96,
      height: 96,
    },
    donorbox: {
      x: 1632,
      y: 672,
      width: 96,
      height: 96,
    },
    donorschoose: {
      x: 1632,
      y: 768,
      width: 96,
      height: 96,
    },
    dotdigital: {
      x: 1728,
      y: 3206,
      width: 96,
      height: 20,
    },
    doubletick: {
      x: 1632,
      y: 864,
      width: 96,
      height: 96,
    },
    dripcel: {
      x: 1632,
      y: 960,
      width: 96,
      height: 96,
    },
    dropboy: {
      x: 1632,
      y: 1056,
      width: 96,
      height: 96,
    },
    dryfta: {
      x: 1632,
      y: 1152,
      width: 96,
      height: 96,
    },
    duda: {
      x: 1632,
      y: 1248,
      width: 96,
      height: 96,
    },
    durianpay: {
      x: 1632,
      y: 1344,
      width: 96,
      height: 96,
    },
    dutchie: {
      x: 1632,
      y: 1440,
      width: 96,
      height: 96,
    },
    dutchie_plus: {
      x: 1632,
      y: 1536,
      width: 96,
      height: 96,
    },
    dynamo_software: {
      x: 0,
      y: 1632,
      width: 96,
      height: 96,
    },
    easypractice: {
      x: 96,
      y: 1632,
      width: 96,
      height: 96,
    },
    easyship: {
      x: 0,
      y: 3206,
      width: 96,
      height: 24,
    },
    ebby: {
      x: 384,
      y: 3206,
      width: 96,
      height: 23,
    },
    ecomail: {
      x: 192,
      y: 1632,
      width: 96,
      height: 96,
    },
    ecompliance: {
      x: 288,
      y: 1632,
      width: 96,
      height: 96,
    },
    eden_ai: {
      x: 384,
      y: 1632,
      width: 96,
      height: 96,
    },
    edume: {
      x: 480,
      y: 1632,
      width: 96,
      height: 96,
    },
    effiliation: {
      x: 576,
      y: 1632,
      width: 96,
      height: 96,
    },
    eia: {
      x: 672,
      y: 1632,
      width: 96,
      height: 96,
    },
    elai: {
      x: 768,
      y: 1632,
      width: 96,
      height: 96,
    },
    elasticemail: {
      x: 864,
      y: 1632,
      width: 96,
      height: 96,
    },
    elemeno: {
      x: 960,
      y: 1632,
      width: 96,
      height: 96,
    },
    elevio: {
      x: 1056,
      y: 1632,
      width: 96,
      height: 96,
    },
    emaillistverify: {
      x: 1152,
      y: 1632,
      width: 96,
      height: 96,
    },
    emailoctopus: {
      x: 1248,
      y: 1632,
      width: 96,
      height: 96,
    },
    emarsys: {
      x: 1344,
      y: 1632,
      width: 96,
      height: 96,
    },
    emfluence: {
      x: 3168,
      y: 2042,
      width: 96,
      height: 50,
    },
    emplifi: {
      x: 1440,
      y: 3206,
      width: 96,
      height: 21,
    },
    emsi: {
      x: 1440,
      y: 1632,
      width: 96,
      height: 96,
    },
    endear: {
      x: 1536,
      y: 1632,
      width: 96,
      height: 96,
    },
    endorsal: {
      x: 1632,
      y: 1632,
      width: 96,
      height: 96,
    },
    engagebay: {
      x: 1728,
      y: 0,
      width: 96,
      height: 96,
    },
    enspire_commerce: {
      x: 1728,
      y: 96,
      width: 96,
      height: 96,
    },
    enterprisealumni: {
      x: 1728,
      y: 192,
      width: 96,
      height: 96,
    },
    enverus: {
      x: 1728,
      y: 288,
      width: 96,
      height: 96,
    },
    envi: {
      x: 1728,
      y: 384,
      width: 96,
      height: 96,
    },
    envoke: {
      x: 1728,
      y: 480,
      width: 96,
      height: 96,
    },
    epsilon3: {
      x: 1728,
      y: 576,
      width: 96,
      height: 96,
    },
    erply: {
      x: 1728,
      y: 672,
      width: 96,
      height: 96,
    },
    esignly: {
      x: 1728,
      y: 768,
      width: 96,
      height: 96,
    },
    eventee: {
      x: 1728,
      y: 864,
      width: 96,
      height: 96,
    },
    eventmaker: {
      x: 1728,
      y: 960,
      width: 96,
      height: 96,
    },
    eventmobi: {
      x: 1728,
      y: 1056,
      width: 96,
      height: 96,
    },
    eventtia: {
      x: 1728,
      y: 1152,
      width: 96,
      height: 96,
    },
    eventzilla: {
      x: 1728,
      y: 1248,
      width: 96,
      height: 96,
    },
    everbridge: {
      x: 1728,
      y: 1344,
      width: 96,
      height: 96,
    },
    everfit: {
      x: 1728,
      y: 1440,
      width: 96,
      height: 96,
    },
    evergiving: {
      x: 3168,
      y: 0,
      width: 96,
      height: 94,
    },
    evernest: {
      x: 1728,
      y: 1536,
      width: 96,
      height: 96,
    },
    everyware: {
      x: 1728,
      y: 1632,
      width: 96,
      height: 96,
    },
    evisort: {
      x: 0,
      y: 1728,
      width: 96,
      height: 96,
    },
    evoliz: {
      x: 96,
      y: 1728,
      width: 96,
      height: 96,
    },
    evvnt: {
      x: 192,
      y: 1728,
      width: 96,
      height: 96,
    },
    ex_co: {
      x: 288,
      y: 1728,
      width: 96,
      height: 96,
    },
    exhibitday: {
      x: 384,
      y: 1728,
      width: 96,
      height: 96,
    },
    ezo_io: {
      x: 480,
      y: 1728,
      width: 96,
      height: 96,
    },
    ezus: {
      x: 576,
      y: 1728,
      width: 96,
      height: 96,
    },
    ezyvet: {
      x: 672,
      y: 1728,
      width: 96,
      height: 96,
    },
    factor_io: {
      x: 768,
      y: 1728,
      width: 96,
      height: 96,
    },
    factoredquality: {
      x: 864,
      y: 1728,
      width: 96,
      height: 96,
    },
    fairing_co: {
      x: 960,
      y: 1728,
      width: 96,
      height: 96,
    },
    fanpower: {
      x: 1056,
      y: 1728,
      width: 96,
      height: 96,
    },
    fantasydata: {
      x: 1152,
      y: 1728,
      width: 96,
      height: 96,
    },
    fareharbor_data: {
      x: 1248,
      y: 1728,
      width: 96,
      height: 96,
    },
    fastly: {
      x: 0,
      y: 3168,
      width: 96,
      height: 38,
    },
    fathom_analytics: {
      x: 1344,
      y: 1728,
      width: 96,
      height: 96,
    },
    fbi_wanted_api: {
      x: 1440,
      y: 1728,
      width: 96,
      height: 96,
    },
    fda: {
      x: 1536,
      y: 1728,
      width: 96,
      height: 96,
    },
    feathery: {
      x: 1632,
      y: 1728,
      width: 96,
      height: 96,
    },
    fee_navigator: {
      x: 1728,
      y: 1728,
      width: 96,
      height: 96,
    },
    feedly: {
      x: 1824,
      y: 0,
      width: 96,
      height: 96,
    },
    fema_gov: {
      x: 1824,
      y: 96,
      width: 96,
      height: 96,
    },
    fera_ai: {
      x: 1824,
      y: 192,
      width: 96,
      height: 96,
    },
    fibery: {
      x: 1824,
      y: 288,
      width: 96,
      height: 96,
    },
    fieldaware: {
      x: 1824,
      y: 384,
      width: 96,
      height: 96,
    },
    fieldfolio: {
      x: 1824,
      y: 480,
      width: 96,
      height: 96,
    },
    fieldly: {
      x: 480,
      y: 3206,
      width: 96,
      height: 23,
    },
    fig_finance: {
      x: 1824,
      y: 576,
      width: 96,
      height: 96,
    },
    figma: {
      x: 3168,
      y: 1506,
      width: 64,
      height: 96,
    },
    finch: {
      x: 1824,
      y: 672,
      width: 96,
      height: 96,
    },
    findymail: {
      x: 1824,
      y: 768,
      width: 96,
      height: 96,
    },
    finmei: {
      x: 1824,
      y: 864,
      width: 96,
      height: 96,
    },
    finmo: {
      x: 1824,
      y: 960,
      width: 96,
      height: 96,
    },
    fintoc: {
      x: 1824,
      y: 1056,
      width: 96,
      height: 96,
    },
    firefish: {
      x: 1824,
      y: 1152,
      width: 96,
      height: 96,
    },
    fireflies: {
      x: 1824,
      y: 1248,
      width: 96,
      height: 96,
    },
    firstpromoter: {
      x: 1824,
      y: 1344,
      width: 96,
      height: 96,
    },
    fixably: {
      x: 1824,
      y: 1440,
      width: 96,
      height: 96,
    },
    fleetio: {
      x: 3168,
      y: 2092,
      width: 96,
      height: 50,
    },
    flip: {
      x: 1824,
      y: 1536,
      width: 96,
      height: 96,
    },
    flodesk: {
      x: 1824,
      y: 1632,
      width: 96,
      height: 96,
    },
    flow: {
      x: 3168,
      y: 2142,
      width: 96,
      height: 50,
    },
    flow_xo: {
      x: 1824,
      y: 1728,
      width: 96,
      height: 96,
    },
    flowcode: {
      x: 0,
      y: 1824,
      width: 96,
      height: 96,
    },
    flux: {
      x: 96,
      y: 1824,
      width: 96,
      height: 96,
    },
    fluxguard: {
      x: 192,
      y: 1824,
      width: 96,
      height: 96,
    },
    fluxiom: {
      x: 288,
      y: 1824,
      width: 96,
      height: 96,
    },
    followupboss: {
      x: 384,
      y: 1824,
      width: 96,
      height: 96,
    },
    fomo: {
      x: 480,
      y: 1824,
      width: 96,
      height: 96,
    },
    formbricks: {
      x: 576,
      y: 1824,
      width: 96,
      height: 96,
    },
    formkeep: {
      x: 672,
      y: 1824,
      width: 96,
      height: 96,
    },
    formsite: {
      x: 768,
      y: 1824,
      width: 96,
      height: 96,
    },
    frame_io: {
      x: 864,
      y: 1824,
      width: 96,
      height: 96,
    },
    franchimp: {
      x: 960,
      y: 1824,
      width: 96,
      height: 96,
    },
    freeagent: {
      x: 2400,
      y: 3206,
      width: 96,
      height: 19,
    },
    freeclimb: {
      x: 1056,
      y: 1824,
      width: 96,
      height: 96,
    },
    freshcaller: {
      x: 1152,
      y: 1824,
      width: 96,
      height: 96,
    },
    freshmarketer: {
      x: 1248,
      y: 1824,
      width: 96,
      height: 96,
    },
    froged: {
      x: 1344,
      y: 1824,
      width: 96,
      height: 96,
    },
    frontegg: {
      x: 1440,
      y: 1824,
      width: 96,
      height: 96,
    },
    fulcrum: {
      x: 1536,
      y: 1824,
      width: 96,
      height: 96,
    },
    fusioo: {
      x: 1632,
      y: 1824,
      width: 96,
      height: 96,
    },
    futurevault: {
      x: 1728,
      y: 1824,
      width: 96,
      height: 96,
    },
    gallabox: {
      x: 1824,
      y: 1824,
      width: 96,
      height: 96,
    },
    gamerpower: {
      x: 1920,
      y: 0,
      width: 96,
      height: 96,
    },
    gan: {
      x: 1920,
      y: 96,
      width: 96,
      height: 96,
    },
    gathercontent: {
      x: 1920,
      y: 192,
      width: 96,
      height: 96,
    },
    gatherup: {
      x: 1920,
      y: 288,
      width: 96,
      height: 96,
    },
    gelato: {
      x: 1920,
      y: 384,
      width: 96,
      height: 96,
    },
    genderize: {
      x: 1920,
      y: 480,
      width: 96,
      height: 96,
    },
    genius_referrals: {
      x: 1920,
      y: 576,
      width: 96,
      height: 96,
    },
    getpaid: {
      x: 1920,
      y: 672,
      width: 96,
      height: 96,
    },
    getresponse: {
      x: 1920,
      y: 768,
      width: 96,
      height: 96,
    },
    getyourguide: {
      x: 1920,
      y: 864,
      width: 96,
      height: 96,
    },
    ghost: {
      x: 3168,
      y: 2591,
      width: 96,
      height: 48,
    },
    giftbit: {
      x: 1920,
      y: 960,
      width: 96,
      height: 96,
    },
    giftpack: {
      x: 1920,
      y: 1056,
      width: 96,
      height: 96,
    },
    giftup: {
      x: 1920,
      y: 1152,
      width: 96,
      height: 96,
    },
    gigs: {
      x: 1920,
      y: 1248,
      width: 96,
      height: 96,
    },
    givebutter: {
      x: 1920,
      y: 1344,
      width: 96,
      height: 96,
    },
    giveforms: {
      x: 1920,
      y: 1440,
      width: 96,
      height: 96,
    },
    givewp: {
      x: 1920,
      y: 1536,
      width: 96,
      height: 96,
    },
    givingfuel: {
      x: 1920,
      y: 1632,
      width: 96,
      height: 96,
    },
    glassfrog: {
      x: 1920,
      y: 1728,
      width: 96,
      height: 96,
    },
    globalgiving: {
      x: 1920,
      y: 1824,
      width: 96,
      height: 96,
    },
    glow: {
      x: 0,
      y: 1920,
      width: 96,
      height: 96,
    },
    goaffpro: {
      x: 96,
      y: 1920,
      width: 96,
      height: 96,
    },
    goflow: {
      x: 192,
      y: 1920,
      width: 96,
      height: 96,
    },
    gong: {
      x: 3168,
      y: 1665,
      width: 96,
      height: 58,
    },
    goody: {
      x: 288,
      y: 1920,
      width: 96,
      height: 96,
    },
    gorilladesk: {
      x: 384,
      y: 1920,
      width: 96,
      height: 96,
    },
    gosquared: {
      x: 480,
      y: 1920,
      width: 96,
      height: 96,
    },
    gotab: {
      x: 672,
      y: 3168,
      width: 96,
      height: 35,
    },
    goto_connect: {
      x: 576,
      y: 1920,
      width: 96,
      height: 96,
    },
    govinfo: {
      x: 672,
      y: 1920,
      width: 96,
      height: 96,
    },
    gptbots: {
      x: 3168,
      y: 1723,
      width: 96,
      height: 54,
    },
    gravity_forms: {
      x: 768,
      y: 1920,
      width: 96,
      height: 96,
    },
    grazecart: {
      x: 864,
      y: 1920,
      width: 96,
      height: 96,
    },
    greenspark: {
      x: 960,
      y: 1920,
      width: 96,
      height: 96,
    },
    greenstreet: {
      x: 3168,
      y: 2735,
      width: 96,
      height: 46,
    },
    griffin: {
      x: 1056,
      y: 1920,
      width: 96,
      height: 96,
    },
    groovehq: {
      x: 1152,
      y: 1920,
      width: 96,
      height: 96,
    },
    gtmhub: {
      x: 1248,
      y: 1920,
      width: 96,
      height: 96,
    },
    guardian_property_systems: {
      x: 1344,
      y: 1920,
      width: 96,
      height: 96,
    },
    guestmeter: {
      x: 864,
      y: 3168,
      width: 96,
      height: 33,
    },
    guesty: {
      x: 1440,
      y: 1920,
      width: 96,
      height: 96,
    },
    guidebook: {
      x: 1536,
      y: 1920,
      width: 96,
      height: 96,
    },
    guidecx: {
      x: 1632,
      y: 1920,
      width: 96,
      height: 96,
    },
    gumroad: {
      x: 672,
      y: 3230,
      width: 96,
      height: 13,
    },
    habitify: {
      x: 1728,
      y: 1920,
      width: 96,
      height: 96,
    },
    hackedu: {
      x: 1824,
      y: 1920,
      width: 96,
      height: 96,
    },
    hackerone: {
      x: 1920,
      y: 1920,
      width: 96,
      height: 96,
    },
    halosecurity: {
      x: 2016,
      y: 0,
      width: 96,
      height: 96,
    },
    handwrytten: {
      x: 2016,
      y: 96,
      width: 96,
      height: 96,
    },
    happierleads: {
      x: 2016,
      y: 192,
      width: 96,
      height: 96,
    },
    happyrobot_ai: {
      x: 2016,
      y: 288,
      width: 96,
      height: 96,
    },
    harmonic_v2: {
      x: 2016,
      y: 384,
      width: 96,
      height: 96,
    },
    harness: {
      x: 2016,
      y: 480,
      width: 96,
      height: 96,
    },
    hcss: {
      x: 2016,
      y: 576,
      width: 96,
      height: 96,
    },
    healthcare_gov: {
      x: 2016,
      y: 672,
      width: 96,
      height: 96,
    },
    helloasso: {
      x: 2016,
      y: 768,
      width: 96,
      height: 96,
    },
    helpcrunch: {
      x: 2016,
      y: 864,
      width: 96,
      height: 96,
    },
    helpjuice: {
      x: 2016,
      y: 960,
      width: 96,
      height: 96,
    },
    hetrixtools: {
      x: 2016,
      y: 1056,
      width: 96,
      height: 96,
    },
    hexomatic: {
      x: 2016,
      y: 1152,
      width: 96,
      height: 96,
    },
    hexometer: {
      x: 2016,
      y: 1248,
      width: 96,
      height: 96,
    },
    heygen: {
      x: 2016,
      y: 1344,
      width: 96,
      height: 96,
    },
    heyreach: {
      x: 3168,
      y: 1777,
      width: 96,
      height: 54,
    },
    hideliver: {
      x: 2016,
      y: 1440,
      width: 96,
      height: 96,
    },
    highlevel: {
      x: 2016,
      y: 1536,
      width: 96,
      height: 96,
    },
    highlevel_v2: {
      x: 2016,
      y: 1632,
      width: 96,
      height: 96,
    },
    highnote: {
      x: 2016,
      y: 1728,
      width: 96,
      height: 96,
    },
    hive_ai: {
      x: 2016,
      y: 1824,
      width: 96,
      height: 96,
    },
    hiveage: {
      x: 2016,
      y: 1920,
      width: 96,
      height: 96,
    },
    holded: {
      x: 0,
      y: 2016,
      width: 96,
      height: 96,
    },
    hologram_io: {
      x: 96,
      y: 2016,
      width: 96,
      height: 96,
    },
    home_river_group: {
      x: 192,
      y: 2016,
      width: 96,
      height: 96,
    },
    homebase: {
      x: 3168,
      y: 2639,
      width: 96,
      height: 48,
    },
    honeycomb: {
      x: 288,
      y: 2016,
      width: 96,
      height: 96,
    },
    hostfully: {
      x: 384,
      y: 2016,
      width: 96,
      height: 96,
    },
    hotjar: {
      x: 480,
      y: 2016,
      width: 96,
      height: 96,
    },
    hotmart: {
      x: 576,
      y: 2016,
      width: 96,
      height: 96,
    },
    housecall_pro: {
      x: 672,
      y: 2016,
      width: 96,
      height: 96,
    },
    howuku: {
      x: 768,
      y: 2016,
      width: 96,
      height: 96,
    },
    hrflow: {
      x: 864,
      y: 2016,
      width: 96,
      height: 96,
    },
    hubilo: {
      x: 960,
      y: 2016,
      width: 96,
      height: 96,
    },
    hubplanner: {
      x: 1056,
      y: 2016,
      width: 96,
      height: 96,
    },
    hubstaff: {
      x: 1152,
      y: 2016,
      width: 96,
      height: 96,
    },
    hudu: {
      x: 1248,
      y: 2016,
      width: 96,
      height: 96,
    },
    humaans: {
      x: 1344,
      y: 2016,
      width: 96,
      height: 96,
    },
    humanitix: {
      x: 1440,
      y: 2016,
      width: 96,
      height: 96,
    },
    hunter: {
      x: 1536,
      y: 2016,
      width: 96,
      height: 96,
    },
    hypeauditor: {
      x: 1632,
      y: 2016,
      width: 96,
      height: 96,
    },
    hyperswitch: {
      x: 1728,
      y: 2016,
      width: 96,
      height: 96,
    },
    hypertrack: {
      x: 1824,
      y: 2016,
      width: 96,
      height: 96,
    },
    icypeas: {
      x: 2784,
      y: 3206,
      width: 96,
      height: 18,
    },
    ideanote_io: {
      x: 1920,
      y: 2016,
      width: 96,
      height: 96,
    },
    idloom: {
      x: 2016,
      y: 2016,
      width: 96,
      height: 96,
    },
    ignitepost: {
      x: 2112,
      y: 0,
      width: 96,
      height: 96,
    },
    illumidesk: {
      x: 2112,
      y: 96,
      width: 96,
      height: 96,
    },
    illumin: {
      x: 2112,
      y: 192,
      width: 96,
      height: 96,
    },
    inbox: {
      x: 2112,
      y: 288,
      width: 96,
      height: 96,
    },
    increase: {
      x: 2112,
      y: 384,
      width: 96,
      height: 96,
    },
    innform: {
      x: 2112,
      y: 480,
      width: 96,
      height: 96,
    },
    insightful: {
      x: 2112,
      y: 576,
      width: 96,
      height: 96,
    },
    instamojo: {
      x: 960,
      y: 3206,
      width: 96,
      height: 22,
    },
    instantly_ai: {
      x: 2112,
      y: 672,
      width: 96,
      height: 96,
    },
    intellihr: {
      x: 2112,
      y: 768,
      width: 96,
      height: 96,
    },
    interact: {
      x: 2112,
      y: 864,
      width: 96,
      height: 96,
    },
    intrinio: {
      x: 3168,
      y: 1991,
      width: 96,
      height: 51,
    },
    intruder_io: {
      x: 2112,
      y: 960,
      width: 96,
      height: 96,
    },
    iso_new_england: {
      x: 2112,
      y: 1056,
      width: 96,
      height: 96,
    },
    itempath: {
      x: 2112,
      y: 1152,
      width: 96,
      height: 96,
    },
    jamf_pro: {
      x: 2112,
      y: 1248,
      width: 96,
      height: 96,
    },
    jamf_protect: {
      x: 2112,
      y: 1344,
      width: 96,
      height: 96,
    },
    jasper: {
      x: 2112,
      y: 1440,
      width: 96,
      height: 96,
    },
    jebbit: {
      x: 2112,
      y: 1536,
      width: 96,
      height: 96,
    },
    jestor: {
      x: 2112,
      y: 1632,
      width: 96,
      height: 96,
    },
    jira_service_management: {
      x: 2112,
      y: 1728,
      width: 96,
      height: 96,
    },
    jobprogress: {
      x: 2112,
      y: 1824,
      width: 96,
      height: 96,
    },
    jobscore: {
      x: 2112,
      y: 1920,
      width: 96,
      height: 96,
    },
    jobtoolz: {
      x: 2112,
      y: 2016,
      width: 96,
      height: 96,
    },
    join: {
      x: 0,
      y: 2112,
      width: 96,
      height: 96,
    },
    joomag: {
      x: 96,
      y: 2112,
      width: 96,
      height: 96,
    },
    journalize: {
      x: 192,
      y: 2112,
      width: 96,
      height: 96,
    },
    journy_io: {
      x: 288,
      y: 2112,
      width: 96,
      height: 96,
    },
    judgeme: {
      x: 384,
      y: 2112,
      width: 96,
      height: 96,
    },
    jumpcloud: {
      x: 480,
      y: 2112,
      width: 96,
      height: 96,
    },
    jumpseller: {
      x: 576,
      y: 2112,
      width: 96,
      height: 96,
    },
    junip: {
      x: 672,
      y: 2112,
      width: 96,
      height: 96,
    },
    jvzoo: {
      x: 768,
      y: 2112,
      width: 96,
      height: 96,
    },
    kallidus_sapling: {
      x: 864,
      y: 2112,
      width: 96,
      height: 96,
    },
    kameleoon: {
      x: 960,
      y: 2112,
      width: 96,
      height: 96,
    },
    karbon: {
      x: 3168,
      y: 2911,
      width: 96,
      height: 42,
    },
    kargo: {
      x: 1056,
      y: 2112,
      width: 96,
      height: 96,
    },
    keap: {
      x: 3168,
      y: 2781,
      width: 96,
      height: 45,
    },
    keap_v2: {
      x: 1152,
      y: 2112,
      width: 96,
      height: 96,
    },
    keen_io: {
      x: 1248,
      y: 2112,
      width: 96,
      height: 96,
    },
    keepcon: {
      x: 1344,
      y: 2112,
      width: 96,
      height: 96,
    },
    kelkoo: {
      x: 3168,
      y: 569,
      width: 96,
      height: 88,
    },
    kibocommerce: {
      x: 1440,
      y: 2112,
      width: 96,
      height: 96,
    },
    kintone: {
      x: 1536,
      y: 2112,
      width: 96,
      height: 96,
    },
    kizeo_forms: {
      x: 1632,
      y: 2112,
      width: 96,
      height: 96,
    },
    klaus: {
      x: 1728,
      y: 2112,
      width: 96,
      height: 96,
    },
    klaviyo_v2: {
      x: 1536,
      y: 3168,
      width: 96,
      height: 28,
    },
    knack: {
      x: 1824,
      y: 2112,
      width: 96,
      height: 96,
    },
    knocommerce: {
      x: 1920,
      y: 2112,
      width: 96,
      height: 96,
    },
    knoema: {
      x: 2016,
      y: 2112,
      width: 96,
      height: 96,
    },
    knowbe4_kmsat_reporting: {
      x: 2112,
      y: 2112,
      width: 96,
      height: 96,
    },
    knowfirst: {
      x: 2208,
      y: 0,
      width: 96,
      height: 96,
    },
    knowledgeowl: {
      x: 3168,
      y: 377,
      width: 90,
      height: 96,
    },
    kolide: {
      x: 2208,
      y: 96,
      width: 96,
      height: 96,
    },
    konnektive: {
      x: 2208,
      y: 192,
      width: 96,
      height: 96,
    },
    kontak: {
      x: 2208,
      y: 288,
      width: 96,
      height: 96,
    },
    kontent_ai: {
      x: 2208,
      y: 384,
      width: 96,
      height: 96,
    },
    kpaehs: {
      x: 2208,
      y: 480,
      width: 96,
      height: 96,
    },
    kvcore: {
      x: 2208,
      y: 576,
      width: 96,
      height: 96,
    },
    kyte: {
      x: 2208,
      y: 672,
      width: 96,
      height: 96,
    },
    lancerkit: {
      x: 2208,
      y: 768,
      width: 96,
      height: 96,
    },
    landbot: {
      x: 2208,
      y: 864,
      width: 96,
      height: 96,
    },
    laravel_forge: {
      x: 2208,
      y: 960,
      width: 96,
      height: 96,
    },
    layup: {
      x: 2208,
      y: 1056,
      width: 96,
      height: 96,
    },
    leaddyno: {
      x: 2208,
      y: 1152,
      width: 96,
      height: 96,
    },
    leadfeeder: {
      x: 2208,
      y: 1248,
      width: 96,
      height: 96,
    },
    leadspedia: {
      x: 2208,
      y: 1344,
      width: 96,
      height: 96,
    },
    leadsquared: {
      x: 2208,
      y: 1440,
      width: 96,
      height: 96,
    },
    leadsrx: {
      x: 2208,
      y: 1536,
      width: 96,
      height: 96,
    },
    leaflink: {
      x: 3168,
      y: 2192,
      width: 96,
      height: 50,
    },
    leap: {
      x: 2208,
      y: 1632,
      width: 96,
      height: 96,
    },
    lemon_markets: {
      x: 2208,
      y: 1728,
      width: 96,
      height: 96,
    },
    lemon_squeezy: {
      x: 2208,
      y: 1824,
      width: 96,
      height: 96,
    },
    leverade: {
      x: 2208,
      y: 1920,
      width: 96,
      height: 96,
    },
    lexoffice: {
      x: 2208,
      y: 2016,
      width: 96,
      height: 96,
    },
    limblecmms: {
      x: 3168,
      y: 284,
      width: 96,
      height: 93,
    },
    linearb: {
      x: 2208,
      y: 2112,
      width: 96,
      height: 96,
    },
    lineup: {
      x: 0,
      y: 2208,
      width: 96,
      height: 96,
    },
    linkace: {
      x: 96,
      y: 2208,
      width: 96,
      height: 96,
    },
    listclean: {
      x: 192,
      y: 2208,
      width: 96,
      height: 96,
    },
    listen_notes: {
      x: 288,
      y: 2208,
      width: 96,
      height: 96,
    },
    listrak_email: {
      x: 384,
      y: 2208,
      width: 96,
      height: 96,
    },
    listrak_sms: {
      x: 480,
      y: 2208,
      width: 96,
      height: 96,
    },
    little_green_light: {
      x: 576,
      y: 2208,
      width: 96,
      height: 96,
    },
    liveagent: {
      x: 672,
      y: 2208,
      width: 96,
      height: 96,
    },
    livesession: {
      x: 768,
      y: 2208,
      width: 96,
      height: 96,
    },
    livestorm: {
      x: 2976,
      y: 3072,
      width: 95,
      height: 96,
    },
    livingdocs_io: {
      x: 864,
      y: 2208,
      width: 96,
      height: 96,
    },
    local_falcon: {
      x: 960,
      y: 2208,
      width: 96,
      height: 96,
    },
    localazy: {
      x: 1056,
      y: 2208,
      width: 96,
      height: 96,
    },
    lodgify: {
      x: 1152,
      y: 2208,
      width: 96,
      height: 96,
    },
    lokalise: {
      x: 96,
      y: 3206,
      width: 96,
      height: 24,
    },
    loyjoy: {
      x: 1248,
      y: 2208,
      width: 96,
      height: 96,
    },
    loyverse: {
      x: 1344,
      y: 2208,
      width: 96,
      height: 96,
    },
    lucidya: {
      x: 1440,
      y: 2208,
      width: 96,
      height: 96,
    },
    lunatask: {
      x: 1536,
      y: 2208,
      width: 96,
      height: 96,
    },
    maestra: {
      x: 1632,
      y: 2208,
      width: 96,
      height: 96,
    },
    maestroqa: {
      x: 1728,
      y: 2208,
      width: 96,
      height: 96,
    },
    magicplan: {
      x: 1824,
      y: 3206,
      width: 96,
      height: 20,
    },
    mailblaze: {
      x: 3168,
      y: 3032,
      width: 96,
      height: 39,
    },
    mailercheck: {
      x: 1824,
      y: 2208,
      width: 96,
      height: 96,
    },
    mailersend: {
      x: 1920,
      y: 2208,
      width: 96,
      height: 96,
    },
    mailify: {
      x: 2016,
      y: 2208,
      width: 96,
      height: 96,
    },
    mailinabox: {
      x: 2112,
      y: 2208,
      width: 96,
      height: 96,
    },
    mailosaur: {
      x: 2208,
      y: 2208,
      width: 96,
      height: 96,
    },
    mailsac: {
      x: 2304,
      y: 0,
      width: 96,
      height: 96,
    },
    mailshake: {
      x: 2304,
      y: 96,
      width: 96,
      height: 96,
    },
    mailslurp: {
      x: 2304,
      y: 192,
      width: 96,
      height: 96,
    },
    mailsoftly: {
      x: 2304,
      y: 288,
      width: 96,
      height: 96,
    },
    maintainx: {
      x: 2304,
      y: 384,
      width: 96,
      height: 96,
    },
    mainwp: {
      x: 2304,
      y: 480,
      width: 96,
      height: 96,
    },
    makeplans: {
      x: 2304,
      y: 576,
      width: 96,
      height: 96,
    },
    manatal: {
      x: 2304,
      y: 672,
      width: 96,
      height: 96,
    },
    manychat: {
      x: 2304,
      y: 768,
      width: 96,
      height: 96,
    },
    mapulus: {
      x: 2304,
      y: 864,
      width: 96,
      height: 96,
    },
    marchex: {
      x: 2304,
      y: 960,
      width: 96,
      height: 96,
    },
    marketaux: {
      x: 2304,
      y: 1056,
      width: 96,
      height: 96,
    },
    maropost: {
      x: 2304,
      y: 1152,
      width: 96,
      height: 96,
    },
    mattermark: {
      x: 2304,
      y: 1248,
      width: 96,
      height: 96,
    },
    mattermost: {
      x: 2304,
      y: 1344,
      width: 96,
      height: 96,
    },
    mautic: {
      x: 2304,
      y: 1440,
      width: 96,
      height: 96,
    },
    mav: {
      x: 2304,
      y: 1536,
      width: 96,
      height: 96,
    },
    mcleod_software: {
      x: 2304,
      y: 1632,
      width: 96,
      height: 96,
    },
    mediasilo: {
      x: 2304,
      y: 1728,
      width: 96,
      height: 96,
    },
    medium: {
      x: 2304,
      y: 1824,
      width: 96,
      height: 96,
    },
    medusa: {
      x: 2304,
      y: 1920,
      width: 96,
      height: 96,
    },
    meetingpulse: {
      x: 2304,
      y: 2016,
      width: 96,
      height: 96,
    },
    megaventory: {
      x: 2304,
      y: 2112,
      width: 96,
      height: 96,
    },
    meld_io: {
      x: 3168,
      y: 2953,
      width: 96,
      height: 40,
    },
    melo: {
      x: 2304,
      y: 2208,
      width: 96,
      height: 96,
    },
    memberstack: {
      x: 0,
      y: 2304,
      width: 96,
      height: 96,
    },
    meraki: {
      x: 3168,
      y: 996,
      width: 96,
      height: 77,
    },
    mercury: {
      x: 96,
      y: 2304,
      width: 96,
      height: 96,
    },
    messageflow: {
      x: 192,
      y: 2304,
      width: 96,
      height: 96,
    },
    messari_io: {
      x: 288,
      y: 2304,
      width: 96,
      height: 96,
    },
    metaplane: {
      x: 384,
      y: 2304,
      width: 96,
      height: 96,
    },
    metatext: {
      x: 480,
      y: 2304,
      width: 96,
      height: 96,
    },
    metricool: {
      x: 576,
      y: 2304,
      width: 96,
      height: 96,
    },
    microsoft_app_store: {
      x: 672,
      y: 2304,
      width: 96,
      height: 96,
    },
    middesk: {
      x: 768,
      y: 2304,
      width: 96,
      height: 96,
    },
    mindbody: {
      x: 864,
      y: 2304,
      width: 96,
      height: 96,
    },
    minutemailer: {
      x: 960,
      y: 2304,
      width: 96,
      height: 96,
    },
    mnemonic: {
      x: 1056,
      y: 2304,
      width: 96,
      height: 96,
    },
    mobile_action: {
      x: 1152,
      y: 2304,
      width: 96,
      height: 96,
    },
    modash_io: {
      x: 1248,
      y: 2304,
      width: 96,
      height: 96,
    },
    modrinth: {
      x: 1344,
      y: 2304,
      width: 96,
      height: 96,
    },
    modusign: {
      x: 1440,
      y: 2304,
      width: 96,
      height: 96,
    },
    mollie: {
      x: 1536,
      y: 2304,
      width: 96,
      height: 96,
    },
    moneybird: {
      x: 1632,
      y: 2304,
      width: 96,
      height: 96,
    },
    monkeylearn: {
      x: 1728,
      y: 2304,
      width: 96,
      height: 96,
    },
    monnify: {
      x: 3072,
      y: 3206,
      width: 96,
      height: 17,
    },
    moodys: {
      x: 1824,
      y: 2304,
      width: 96,
      height: 96,
    },
    moonclerk: {
      x: 1920,
      y: 2304,
      width: 96,
      height: 96,
    },
    moonmail: {
      x: 2016,
      y: 2304,
      width: 96,
      height: 96,
    },
    moosend: {
      x: 2112,
      y: 2304,
      width: 96,
      height: 96,
    },
    motiontools: {
      x: 2208,
      y: 2304,
      width: 96,
      height: 96,
    },
    mrpeasy: {
      x: 2304,
      y: 2304,
      width: 96,
      height: 96,
    },
    msuite: {
      x: 2400,
      y: 0,
      width: 96,
      height: 96,
    },
    myclub: {
      x: 2400,
      y: 96,
      width: 96,
      height: 96,
    },
    mydevices: {
      x: 2400,
      y: 192,
      width: 96,
      height: 96,
    },
    myemma: {
      x: 2400,
      y: 288,
      width: 96,
      height: 96,
    },
    mytime: {
      x: 2400,
      y: 384,
      width: 96,
      height: 96,
    },
    n8n: {
      x: 2400,
      y: 480,
      width: 96,
      height: 96,
    },
    national_park_service: {
      x: 2400,
      y: 576,
      width: 96,
      height: 96,
    },
    ncdc: {
      x: 2400,
      y: 672,
      width: 96,
      height: 96,
    },
    ncr: {
      x: 2400,
      y: 768,
      width: 96,
      height: 96,
    },
    ncreif: {
      x: 2400,
      y: 864,
      width: 96,
      height: 96,
    },
    ncscale: {
      x: 2400,
      y: 960,
      width: 96,
      height: 96,
    },
    neoncrm: {
      x: 2400,
      y: 1056,
      width: 96,
      height: 96,
    },
    neverbounce: {
      x: 2400,
      y: 1152,
      width: 96,
      height: 96,
    },
    new_relic: {
      x: 3168,
      y: 3206,
      width: 96,
      height: 17,
    },
    news_api: {
      x: 2400,
      y: 1248,
      width: 96,
      height: 96,
    },
    newscred: {
      x: 2400,
      y: 1344,
      width: 96,
      height: 96,
    },
    nicereply: {
      x: 2400,
      y: 1440,
      width: 96,
      height: 96,
    },
    nifty: {
      x: 2400,
      y: 1536,
      width: 96,
      height: 96,
    },
    nifty_images: {
      x: 3168,
      y: 1410,
      width: 66,
      height: 96,
    },
    nimble: {
      x: 2400,
      y: 1632,
      width: 96,
      height: 96,
    },
    nimbleway: {
      x: 2400,
      y: 1728,
      width: 96,
      height: 96,
    },
    nist: {
      x: 2400,
      y: 1824,
      width: 96,
      height: 96,
    },
    nmap: {
      x: 2400,
      y: 1920,
      width: 96,
      height: 96,
    },
    nmkr: {
      x: 2400,
      y: 2016,
      width: 96,
      height: 96,
    },
    nomad: {
      x: 3168,
      y: 900,
      width: 78,
      height: 96,
    },
    northbeam: {
      x: 2400,
      y: 2112,
      width: 96,
      height: 96,
    },
    nrel_gov: {
      x: 2400,
      y: 2208,
      width: 96,
      height: 96,
    },
    nutshell: {
      x: 576,
      y: 3230,
      width: 96,
      height: 14,
    },
    nuviad: {
      x: 2400,
      y: 2304,
      width: 96,
      height: 96,
    },
    nyckel: {
      x: 1248,
      y: 3168,
      width: 96,
      height: 30,
    },
    ofac: {
      x: 0,
      y: 2400,
      width: 96,
      height: 96,
    },
    offerslook: {
      x: 96,
      y: 2400,
      width: 96,
      height: 96,
    },
    officernd_flex: {
      x: 192,
      y: 2400,
      width: 96,
      height: 96,
    },
    officernd_hybrid: {
      x: 288,
      y: 2400,
      width: 96,
      height: 96,
    },
    oktopost: {
      x: 384,
      y: 2400,
      width: 96,
      height: 96,
    },
    omnisend: {
      x: 480,
      y: 2400,
      width: 96,
      height: 96,
    },
    omnitracs: {
      x: 576,
      y: 2400,
      width: 96,
      height: 96,
    },
    onboard: {
      x: 672,
      y: 2400,
      width: 96,
      height: 96,
    },
    onesignal: {
      x: 768,
      y: 2400,
      width: 96,
      height: 96,
    },
    onetrust: {
      x: 0,
      y: 3230,
      width: 96,
      height: 17,
    },
    onfleet: {
      x: 864,
      y: 2400,
      width: 96,
      height: 96,
    },
    onpay: {
      x: 960,
      y: 2400,
      width: 96,
      height: 96,
    },
    ontraport: {
      x: 480,
      y: 3230,
      width: 96,
      height: 15,
    },
    openai: {
      x: 1056,
      y: 2400,
      width: 96,
      height: 96,
    },
    openscreen: {
      x: 1152,
      y: 2400,
      width: 96,
      height: 96,
    },
    optimoroute: {
      x: 1248,
      y: 2400,
      width: 96,
      height: 96,
    },
    optmyzr: {
      x: 1344,
      y: 2400,
      width: 96,
      height: 96,
    },
    order_time: {
      x: 1440,
      y: 2400,
      width: 96,
      height: 96,
    },
    orderdesk: {
      x: 1536,
      y: 2400,
      width: 96,
      height: 96,
    },
    orderful: {
      x: 1632,
      y: 2400,
      width: 96,
      height: 96,
    },
    ordermate_io: {
      x: 1728,
      y: 2400,
      width: 96,
      height: 96,
    },
    orderspace: {
      x: 1824,
      y: 2400,
      width: 96,
      height: 96,
    },
    outline: {
      x: 1920,
      y: 2400,
      width: 96,
      height: 96,
    },
    outscraper: {
      x: 2016,
      y: 2400,
      width: 96,
      height: 96,
    },
    outseta: {
      x: 2112,
      y: 2400,
      width: 96,
      height: 96,
    },
    oxford_economics: {
      x: 2208,
      y: 2400,
      width: 96,
      height: 96,
    },
    oxylabs_io: {
      x: 3168,
      y: 1146,
      width: 72,
      height: 96,
    },
    pabbly_email_marketing: {
      x: 2304,
      y: 2400,
      width: 96,
      height: 96,
    },
    packagex: {
      x: 2400,
      y: 2400,
      width: 96,
      height: 96,
    },
    paigo: {
      x: 2496,
      y: 0,
      width: 96,
      height: 96,
    },
    palantir_foundry: {
      x: 2496,
      y: 96,
      width: 96,
      height: 96,
    },
    paperform: {
      x: 2496,
      y: 192,
      width: 96,
      height: 96,
    },
    papersurvey: {
      x: 2496,
      y: 288,
      width: 96,
      height: 96,
    },
    parcellab: {
      x: 2496,
      y: 384,
      width: 96,
      height: 96,
    },
    paycor: {
      x: 2496,
      y: 480,
      width: 96,
      height: 96,
    },
    payhere: {
      x: 2496,
      y: 576,
      width: 96,
      height: 96,
    },
    paylocity: {
      x: 2592,
      y: 3168,
      width: 96,
      height: 26,
    },
    paystack: {
      x: 2496,
      y: 672,
      width: 96,
      height: 96,
    },
    peakon: {
      x: 2496,
      y: 768,
      width: 96,
      height: 96,
    },
    pencil_spaces: {
      x: 2496,
      y: 864,
      width: 96,
      height: 96,
    },
    pennylane: {
      x: 2496,
      y: 960,
      width: 96,
      height: 96,
    },
    pensionpro: {
      x: 2496,
      y: 1056,
      width: 96,
      height: 96,
    },
    peopleforce: {
      x: 2496,
      y: 3206,
      width: 96,
      height: 19,
    },
    pepperjam: {
      x: 576,
      y: 3206,
      width: 96,
      height: 23,
    },
    perceptyx: {
      x: 2496,
      y: 1152,
      width: 96,
      height: 96,
    },
    persistiq: {
      x: 2496,
      y: 1248,
      width: 96,
      height: 96,
    },
    pexels: {
      x: 2496,
      y: 1344,
      width: 96,
      height: 96,
    },
    phantombuster: {
      x: 2496,
      y: 1440,
      width: 96,
      height: 96,
    },
    pheedloop: {
      x: 2496,
      y: 1536,
      width: 96,
      height: 96,
    },
    piano: {
      x: 2496,
      y: 1632,
      width: 96,
      height: 96,
    },
    pinecone: {
      x: 2496,
      y: 1728,
      width: 96,
      height: 96,
    },
    pingbell: {
      x: 2496,
      y: 1824,
      width: 96,
      height: 96,
    },
    pingboard: {
      x: 3168,
      y: 1242,
      width: 96,
      height: 72,
    },
    pipelinecrm: {
      x: 2496,
      y: 1920,
      width: 96,
      height: 96,
    },
    pipeliner: {
      x: 2496,
      y: 2016,
      width: 96,
      height: 96,
    },
    pitchbox: {
      x: 2496,
      y: 2112,
      width: 96,
      height: 96,
    },
    plaid: {
      x: 480,
      y: 3168,
      width: 96,
      height: 37,
    },
    planet: {
      x: 3168,
      y: 2687,
      width: 96,
      height: 48,
    },
    plausible: {
      x: 2784,
      y: 3168,
      width: 96,
      height: 25,
    },
    pledge: {
      x: 2496,
      y: 2208,
      width: 96,
      height: 96,
    },
    plezi: {
      x: 2496,
      y: 2304,
      width: 96,
      height: 96,
    },
    plivo: {
      x: 2496,
      y: 2400,
      width: 96,
      height: 96,
    },
    plugandpay: {
      x: 96,
      y: 3168,
      width: 96,
      height: 38,
    },
    plugnpaid: {
      x: 0,
      y: 2496,
      width: 96,
      height: 96,
    },
    pluralsight: {
      x: 96,
      y: 2496,
      width: 96,
      height: 96,
    },
    podium: {
      x: 192,
      y: 2496,
      width: 96,
      height: 96,
    },
    pointagram: {
      x: 288,
      y: 2496,
      width: 96,
      height: 96,
    },
    pointerpro: {
      x: 384,
      y: 2496,
      width: 96,
      height: 96,
    },
    polygon_io: {
      x: 480,
      y: 2496,
      width: 96,
      height: 96,
    },
    ponto: {
      x: 3168,
      y: 2242,
      width: 96,
      height: 50,
    },
    popl: {
      x: 576,
      y: 2496,
      width: 96,
      height: 96,
    },
    portable: {
      x: 672,
      y: 2496,
      width: 96,
      height: 96,
    },
    portable_admin: {
      x: 768,
      y: 2496,
      width: 96,
      height: 96,
    },
    portaone: {
      x: 864,
      y: 2496,
      width: 96,
      height: 96,
    },
    post_cheetah: {
      x: 3168,
      y: 94,
      width: 94,
      height: 96,
    },
    postalytics: {
      x: 960,
      y: 2496,
      width: 96,
      height: 96,
    },
    postgrid: {
      x: 1056,
      y: 2496,
      width: 96,
      height: 96,
    },
    postscript_io: {
      x: 1152,
      y: 2496,
      width: 96,
      height: 96,
    },
    postup: {
      x: 1248,
      y: 2496,
      width: 96,
      height: 96,
    },
    practice_better: {
      x: 1344,
      y: 2496,
      width: 96,
      height: 96,
    },
    precisefp: {
      x: 1440,
      y: 2496,
      width: 96,
      height: 96,
    },
    predicthq: {
      x: 1536,
      y: 2496,
      width: 96,
      height: 96,
    },
    prefinery: {
      x: 1632,
      y: 2496,
      width: 96,
      height: 96,
    },
    priceblocs: {
      x: 1728,
      y: 2496,
      width: 96,
      height: 96,
    },
    pricehubble: {
      x: 1824,
      y: 2496,
      width: 96,
      height: 96,
    },
    printbox: {
      x: 672,
      y: 3206,
      width: 96,
      height: 23,
    },
    printfection: {
      x: 1920,
      y: 2496,
      width: 96,
      height: 96,
    },
    private_ai: {
      x: 2016,
      y: 2496,
      width: 96,
      height: 96,
    },
    process_street: {
      x: 2112,
      y: 2496,
      width: 96,
      height: 96,
    },
    proda_ai: {
      x: 2208,
      y: 2496,
      width: 96,
      height: 96,
    },
    prodpad: {
      x: 2304,
      y: 2496,
      width: 96,
      height: 96,
    },
    productlane: {
      x: 2400,
      y: 2496,
      width: 96,
      height: 96,
    },
    productplan: {
      x: 2496,
      y: 2496,
      width: 96,
      height: 96,
    },
    productsup: {
      x: 2592,
      y: 0,
      width: 96,
      height: 96,
    },
    profitsage: {
      x: 2592,
      y: 96,
      width: 96,
      height: 96,
    },
    profitwell: {
      x: 2592,
      y: 192,
      width: 96,
      height: 96,
    },
    promptmate: {
      x: 2592,
      y: 288,
      width: 96,
      height: 96,
    },
    proof: {
      x: 2592,
      y: 384,
      width: 96,
      height: 96,
    },
    proofserve: {
      x: 2592,
      y: 480,
      width: 96,
      height: 96,
    },
    propexo: {
      x: 1728,
      y: 3168,
      width: 96,
      height: 27,
    },
    proprofs_project: {
      x: 2592,
      y: 576,
      width: 96,
      height: 96,
    },
    proxiedmail: {
      x: 2592,
      y: 672,
      width: 96,
      height: 96,
    },
    proz: {
      x: 2592,
      y: 768,
      width: 96,
      height: 96,
    },
    publisherkit: {
      x: 2592,
      y: 864,
      width: 96,
      height: 96,
    },
    pulse: {
      x: 2592,
      y: 960,
      width: 96,
      height: 96,
    },
    pulse_grow: {
      x: 2592,
      y: 1056,
      width: 96,
      height: 96,
    },
    pumble: {
      x: 2880,
      y: 3168,
      width: 96,
      height: 25,
    },
    pushcut: {
      x: 2592,
      y: 1152,
      width: 96,
      height: 96,
    },
    qdrant: {
      x: 2592,
      y: 1248,
      width: 96,
      height: 96,
    },
    qminder: {
      x: 3168,
      y: 1831,
      width: 96,
      height: 54,
    },
    qolopay: {
      x: 2592,
      y: 1344,
      width: 96,
      height: 96,
    },
    qonto: {
      x: 2592,
      y: 1440,
      width: 96,
      height: 96,
    },
    qoyod: {
      x: 2592,
      y: 1536,
      width: 96,
      height: 96,
    },
    quaderno: {
      x: 2592,
      y: 1632,
      width: 96,
      height: 96,
    },
    qualified_io: {
      x: 2592,
      y: 1728,
      width: 96,
      height: 96,
    },
    quickbase: {
      x: 96,
      y: 3230,
      width: 96,
      height: 16,
    },
    quickbooks_reports: {
      x: 2592,
      y: 1824,
      width: 96,
      height: 96,
    },
    quotapath: {
      x: 2592,
      y: 1920,
      width: 96,
      height: 96,
    },
    qwilr: {
      x: 2592,
      y: 2016,
      width: 96,
      height: 96,
    },
    radar: {
      x: 2592,
      y: 2112,
      width: 96,
      height: 96,
    },
    radio_co: {
      x: 2592,
      y: 2208,
      width: 96,
      height: 96,
    },
    raisedonors: {
      x: 2592,
      y: 2304,
      width: 96,
      height: 96,
    },
    raisely: {
      x: 2592,
      y: 2400,
      width: 96,
      height: 96,
    },
    range: {
      x: 2592,
      y: 2496,
      width: 96,
      height: 96,
    },
    range_co: {
      x: 0,
      y: 2592,
      width: 96,
      height: 96,
    },
    rapid7: {
      x: 96,
      y: 2592,
      width: 96,
      height: 96,
    },
    rapidpro_io: {
      x: 192,
      y: 2592,
      width: 96,
      height: 96,
    },
    raventools: {
      x: 288,
      y: 2592,
      width: 96,
      height: 96,
    },
    razorpay: {
      x: 384,
      y: 2592,
      width: 96,
      height: 96,
    },
    reachinbox: {
      x: 2880,
      y: 3206,
      width: 96,
      height: 18,
    },
    realhub: {
      x: 480,
      y: 2592,
      width: 96,
      height: 96,
    },
    reamaze: {
      x: 576,
      y: 2592,
      width: 96,
      height: 96,
    },
    rebrandly: {
      x: 672,
      y: 2592,
      width: 96,
      height: 96,
    },
    recreation_gov: {
      x: 768,
      y: 2592,
      width: 96,
      height: 96,
    },
    recruit_crm: {
      x: 864,
      y: 2592,
      width: 96,
      height: 96,
    },
    recruitee: {
      x: 3168,
      y: 1602,
      width: 96,
      height: 63,
    },
    recruiterflow: {
      x: 960,
      y: 2592,
      width: 96,
      height: 96,
    },
    redtrackio: {
      x: 1056,
      y: 2592,
      width: 96,
      height: 96,
    },
    reductstore: {
      x: 1152,
      y: 2592,
      width: 96,
      height: 96,
    },
    reepay: {
      x: 1248,
      y: 2592,
      width: 96,
      height: 96,
    },
    referral_rock: {
      x: 1344,
      y: 2592,
      width: 96,
      height: 96,
    },
    referrizer: {
      x: 1440,
      y: 2592,
      width: 96,
      height: 96,
    },
    refersion: {
      x: 1536,
      y: 2592,
      width: 96,
      height: 96,
    },
    refiner_io: {
      x: 1632,
      y: 2592,
      width: 96,
      height: 96,
    },
    reflect: {
      x: 1728,
      y: 2592,
      width: 96,
      height: 96,
    },
    rejoiner: {
      x: 1824,
      y: 2592,
      width: 96,
      height: 96,
    },
    relavate: {
      x: 768,
      y: 3206,
      width: 96,
      height: 23,
    },
    relevance_ai: {
      x: 1920,
      y: 2592,
      width: 96,
      height: 96,
    },
    reloadly: {
      x: 2016,
      y: 2592,
      width: 96,
      height: 96,
    },
    remarkety: {
      x: 2112,
      y: 2592,
      width: 96,
      height: 96,
    },
    renderform_io: {
      x: 2208,
      y: 2592,
      width: 96,
      height: 96,
    },
    rentcast: {
      x: 2304,
      y: 2592,
      width: 96,
      height: 96,
    },
    rently: {
      x: 2400,
      y: 2592,
      width: 96,
      height: 96,
    },
    repliq: {
      x: 2496,
      y: 2592,
      width: 96,
      height: 96,
    },
    replyify: {
      x: 2592,
      y: 2592,
      width: 96,
      height: 96,
    },
    repsly: {
      x: 2688,
      y: 0,
      width: 96,
      height: 96,
    },
    reputation: {
      x: 2688,
      y: 96,
      width: 96,
      height: 96,
    },
    respond_io: {
      x: 2688,
      y: 192,
      width: 96,
      height: 96,
    },
    restaurant365: {
      x: 2688,
      y: 288,
      width: 96,
      height: 96,
    },
    retable: {
      x: 1920,
      y: 3206,
      width: 96,
      height: 20,
    },
    reteach: {
      x: 2688,
      y: 384,
      width: 96,
      height: 96,
    },
    reteno: {
      x: 2688,
      y: 480,
      width: 96,
      height: 96,
    },
    retool: {
      x: 3168,
      y: 2292,
      width: 96,
      height: 50,
    },
    retriever: {
      x: 2688,
      y: 576,
      width: 96,
      height: 96,
    },
    returngo: {
      x: 2688,
      y: 672,
      width: 96,
      height: 96,
    },
    returnless: {
      x: 2688,
      y: 768,
      width: 96,
      height: 96,
    },
    returnlogic: {
      x: 2688,
      y: 864,
      width: 96,
      height: 96,
    },
    returnly: {
      x: 2688,
      y: 960,
      width: 96,
      height: 96,
    },
    rev: {
      x: 2688,
      y: 1056,
      width: 96,
      height: 96,
    },
    revcontent: {
      x: 2688,
      y: 1152,
      width: 96,
      height: 96,
    },
    revi_io: {
      x: 2688,
      y: 1248,
      width: 96,
      height: 96,
    },
    reviewflowz: {
      x: 2688,
      y: 1344,
      width: 96,
      height: 96,
    },
    reviews: {
      x: 2688,
      y: 1440,
      width: 96,
      height: 96,
    },
    revolut_merchant: {
      x: 1056,
      y: 3206,
      width: 96,
      height: 22,
    },
    rezdy: {
      x: 2688,
      y: 1536,
      width: 96,
      height: 96,
    },
    rhythm: {
      x: 2688,
      y: 1632,
      width: 96,
      height: 96,
    },
    rise: {
      x: 2688,
      y: 1728,
      width: 96,
      height: 96,
    },
    rise_ai: {
      x: 2688,
      y: 1824,
      width: 96,
      height: 96,
    },
    riskadvisor: {
      x: 2688,
      y: 1920,
      width: 96,
      height: 96,
    },
    roam_ai: {
      x: 2688,
      y: 2016,
      width: 96,
      height: 96,
    },
    roboflow: {
      x: 2688,
      y: 2112,
      width: 96,
      height: 96,
    },
    rocketreach: {
      x: 2688,
      y: 2208,
      width: 96,
      height: 96,
    },
    rotessa: {
      x: 2688,
      y: 2304,
      width: 96,
      height: 96,
    },
    routelogic: {
      x: 2688,
      y: 2400,
      width: 96,
      height: 96,
    },
    rudder: {
      x: 2688,
      y: 2496,
      width: 96,
      height: 96,
    },
    runa_io: {
      x: 192,
      y: 3168,
      width: 96,
      height: 38,
    },
    ryver: {
      x: 2688,
      y: 2592,
      width: 96,
      height: 96,
    },
    sailpoint: {
      x: 0,
      y: 2688,
      width: 96,
      height: 96,
    },
    salesflare: {
      x: 96,
      y: 2688,
      width: 96,
      height: 96,
    },
    salesmachine: {
      x: 192,
      y: 2688,
      width: 96,
      height: 96,
    },
    salesmanager: {
      x: 864,
      y: 3206,
      width: 96,
      height: 23,
    },
    salesmanago: {
      x: 288,
      y: 2688,
      width: 96,
      height: 96,
    },
    salesmate: {
      x: 384,
      y: 2688,
      width: 96,
      height: 96,
    },
    salesrabbit: {
      x: 480,
      y: 2688,
      width: 96,
      height: 96,
    },
    samcart: {
      x: 576,
      y: 2688,
      width: 96,
      height: 96,
    },
    sarbacane: {
      x: 672,
      y: 2688,
      width: 96,
      height: 96,
    },
    satismeter: {
      x: 768,
      y: 2688,
      width: 96,
      height: 96,
    },
    savvycal: {
      x: 864,
      y: 2688,
      width: 96,
      height: 96,
    },
    scalefusion: {
      x: 960,
      y: 2688,
      width: 96,
      height: 96,
    },
    scalyr: {
      x: 1056,
      y: 2688,
      width: 96,
      height: 96,
    },
    scanifly: {
      x: 1152,
      y: 2688,
      width: 96,
      height: 96,
    },
    scarf_sh: {
      x: 1248,
      y: 2688,
      width: 96,
      height: 96,
    },
    scenes: {
      x: 1344,
      y: 2688,
      width: 96,
      height: 96,
    },
    scout_apm: {
      x: 1440,
      y: 2688,
      width: 96,
      height: 96,
    },
    scout_talent: {
      x: 1536,
      y: 2688,
      width: 96,
      height: 96,
    },
    screendesk: {
      x: 1632,
      y: 2688,
      width: 96,
      height: 96,
    },
    scribeless: {
      x: 1728,
      y: 2688,
      width: 96,
      height: 96,
    },
    search_io: {
      x: 1824,
      y: 2688,
      width: 96,
      height: 96,
    },
    secoda: {
      x: 1920,
      y: 2688,
      width: 96,
      height: 96,
    },
    seidat: {
      x: 2016,
      y: 2688,
      width: 96,
      height: 96,
    },
    sellbrite: {
      x: 2112,
      y: 2688,
      width: 96,
      height: 96,
    },
    sellerchamp: {
      x: 2208,
      y: 2688,
      width: 96,
      height: 96,
    },
    sellix: {
      x: 1824,
      y: 3168,
      width: 96,
      height: 27,
    },
    senar: {
      x: 2304,
      y: 2688,
      width: 96,
      height: 96,
    },
    sendbird: {
      x: 2400,
      y: 2688,
      width: 96,
      height: 96,
    },
    sender: {
      x: 2496,
      y: 2688,
      width: 96,
      height: 96,
    },
    sendlane: {
      x: 2592,
      y: 2688,
      width: 96,
      height: 96,
    },
    sendowl: {
      x: 2688,
      y: 2688,
      width: 96,
      height: 96,
    },
    sendpulse: {
      x: 2784,
      y: 0,
      width: 96,
      height: 96,
    },
    sendwithus: {
      x: 2784,
      y: 96,
      width: 96,
      height: 96,
    },
    senja: {
      x: 2784,
      y: 192,
      width: 96,
      height: 96,
    },
    sensor_industries: {
      x: 2784,
      y: 288,
      width: 96,
      height: 96,
    },
    sertifier: {
      x: 2784,
      y: 384,
      width: 96,
      height: 96,
    },
    servicefusion: {
      x: 2784,
      y: 480,
      width: 96,
      height: 96,
    },
    seven: {
      x: 2784,
      y: 576,
      width: 96,
      height: 96,
    },
    sevenrooms: {
      x: 2784,
      y: 672,
      width: 96,
      height: 96,
    },
    sharpspring: {
      x: 2784,
      y: 768,
      width: 96,
      height: 96,
    },
    shift4: {
      x: 2016,
      y: 3206,
      width: 96,
      height: 20,
    },
    shipbob: {
      x: 2784,
      y: 864,
      width: 96,
      height: 96,
    },
    shipcloud: {
      x: 2784,
      y: 960,
      width: 96,
      height: 96,
    },
    shipday: {
      x: 2784,
      y: 1056,
      width: 96,
      height: 96,
    },
    shipengine: {
      x: 2784,
      y: 1152,
      width: 96,
      height: 96,
    },
    shiphawk: {
      x: 2784,
      y: 1248,
      width: 96,
      height: 96,
    },
    shipmondo: {
      x: 2784,
      y: 1344,
      width: 96,
      height: 96,
    },
    shipmonk: {
      x: 2784,
      y: 1440,
      width: 96,
      height: 96,
    },
    shippo: {
      x: 2784,
      y: 1536,
      width: 96,
      height: 96,
    },
    shippypro: {
      x: 2784,
      y: 1632,
      width: 96,
      height: 96,
    },
    shiptime: {
      x: 2784,
      y: 1728,
      width: 96,
      height: 96,
    },
    shipwire: {
      x: 2784,
      y: 1824,
      width: 96,
      height: 96,
    },
    shipyardapp: {
      x: 2784,
      y: 1920,
      width: 96,
      height: 96,
    },
    shop_ware: {
      x: 2784,
      y: 2016,
      width: 96,
      height: 96,
    },
    shopify_graphql: {
      x: 2784,
      y: 2112,
      width: 96,
      height: 96,
    },
    shoprocket: {
      x: 2784,
      y: 2208,
      width: 96,
      height: 96,
    },
    shotstack: {
      x: 2784,
      y: 2304,
      width: 96,
      height: 96,
    },
    showpad: {
      x: 2784,
      y: 2400,
      width: 96,
      height: 96,
    },
    sidetracker: {
      x: 2784,
      y: 2496,
      width: 96,
      height: 96,
    },
    signable: {
      x: 2784,
      y: 2592,
      width: 96,
      height: 96,
    },
    signaturely: {
      x: 2784,
      y: 2688,
      width: 96,
      height: 96,
    },
    signrequest: {
      x: 0,
      y: 2784,
      width: 96,
      height: 96,
    },
    signwell: {
      x: 96,
      y: 2784,
      width: 96,
      height: 96,
    },
    simplebackups: {
      x: 192,
      y: 2784,
      width: 96,
      height: 96,
    },
    simplero: {
      x: 288,
      y: 2784,
      width: 96,
      height: 96,
    },
    simplescraper: {
      x: 384,
      y: 2784,
      width: 96,
      height: 96,
    },
    simpletexting: {
      x: 480,
      y: 2784,
      width: 96,
      height: 96,
    },
    simplicate: {
      x: 576,
      y: 2784,
      width: 96,
      height: 96,
    },
    skio: {
      x: 672,
      y: 2784,
      width: 96,
      height: 96,
    },
    skulabs: {
      x: 768,
      y: 2784,
      width: 96,
      height: 96,
    },
    skyprep: {
      x: 864,
      y: 2784,
      width: 96,
      height: 96,
    },
    skyslope: {
      x: 960,
      y: 2784,
      width: 96,
      height: 96,
    },
    slack_audit_api: {
      x: 1056,
      y: 2784,
      width: 96,
      height: 96,
    },
    slashid: {
      x: 1152,
      y: 2784,
      width: 96,
      height: 96,
    },
    slicktext: {
      x: 1248,
      y: 2784,
      width: 96,
      height: 96,
    },
    slite: {
      x: 1344,
      y: 2784,
      width: 96,
      height: 96,
    },
    smartlead_ai: {
      x: 1440,
      y: 2784,
      width: 96,
      height: 96,
    },
    smartreach: {
      x: 1536,
      y: 2784,
      width: 96,
      height: 96,
    },
    smartrr: {
      x: 1632,
      y: 2784,
      width: 96,
      height: 96,
    },
    smoove: {
      x: 1728,
      y: 2784,
      width: 96,
      height: 96,
    },
    snapchat: {
      x: 1824,
      y: 2784,
      width: 96,
      height: 96,
    },
    snapdocs: {
      x: 1920,
      y: 2784,
      width: 96,
      height: 96,
    },
    snapdocs_mobile_notary: {
      x: 2016,
      y: 2784,
      width: 96,
      height: 96,
    },
    snov_io: {
      x: 2112,
      y: 2784,
      width: 96,
      height: 96,
    },
    solar: {
      x: 2208,
      y: 2784,
      width: 96,
      height: 96,
    },
    sonderplan: {
      x: 3168,
      y: 3071,
      width: 96,
      height: 39,
    },
    sonix: {
      x: 288,
      y: 3168,
      width: 96,
      height: 38,
    },
    sortly: {
      x: 3168,
      y: 2826,
      width: 96,
      height: 43,
    },
    soundee: {
      x: 2304,
      y: 2784,
      width: 96,
      height: 96,
    },
    sparkhire: {
      x: 2400,
      y: 2784,
      width: 96,
      height: 96,
    },
    speedcurve: {
      x: 2496,
      y: 2784,
      width: 96,
      height: 96,
    },
    spinupwp: {
      x: 192,
      y: 3230,
      width: 96,
      height: 16,
    },
    splitwise: {
      x: 2592,
      y: 2784,
      width: 96,
      height: 96,
    },
    splynx: {
      x: 2688,
      y: 2784,
      width: 96,
      height: 96,
    },
    spoke_phone: {
      x: 2784,
      y: 2784,
      width: 96,
      height: 96,
    },
    sportmonks: {
      x: 2880,
      y: 0,
      width: 96,
      height: 96,
    },
    spotify_web: {
      x: 2880,
      y: 96,
      width: 96,
      height: 96,
    },
    spotme: {
      x: 2880,
      y: 192,
      width: 96,
      height: 96,
    },
    spreecommerce: {
      x: 2880,
      y: 288,
      width: 96,
      height: 96,
    },
    sqldbm: {
      x: 2880,
      y: 384,
      width: 96,
      height: 96,
    },
    stamped: {
      x: 2880,
      y: 480,
      width: 96,
      height: 96,
    },
    starion: {
      x: 1152,
      y: 3168,
      width: 96,
      height: 31,
    },
    startinfinity: {
      x: 2880,
      y: 576,
      width: 96,
      height: 96,
    },
    starton: {
      x: 1440,
      y: 3168,
      width: 96,
      height: 29,
    },
    statorium: {
      x: 288,
      y: 3230,
      width: 96,
      height: 16,
    },
    status_hero: {
      x: 2880,
      y: 672,
      width: 96,
      height: 96,
    },
    stedi: {
      x: 2880,
      y: 768,
      width: 96,
      height: 96,
    },
    stellaconnect: {
      x: 2880,
      y: 864,
      width: 96,
      height: 96,
    },
    stiply: {
      x: 2880,
      y: 960,
      width: 96,
      height: 96,
    },
    stlouisfed: {
      x: 2880,
      y: 1056,
      width: 96,
      height: 96,
    },
    stord: {
      x: 2880,
      y: 1152,
      width: 96,
      height: 96,
    },
    storyblok: {
      x: 2880,
      y: 1248,
      width: 96,
      height: 96,
    },
    storychief: {
      x: 2880,
      y: 1344,
      width: 96,
      height: 96,
    },
    streamtime: {
      x: 2880,
      y: 1440,
      width: 96,
      height: 96,
    },
    submittable: {
      x: 2880,
      y: 1536,
      width: 96,
      height: 96,
    },
    super_ai: {
      x: 2880,
      y: 1632,
      width: 96,
      height: 96,
    },
    super_dispatch: {
      x: 2880,
      y: 1728,
      width: 96,
      height: 96,
    },
    superfiliate: {
      x: 2880,
      y: 1824,
      width: 96,
      height: 96,
    },
    supersend: {
      x: 2880,
      y: 1920,
      width: 96,
      height: 96,
    },
    support_hub: {
      x: 2880,
      y: 2016,
      width: 96,
      height: 96,
    },
    surecart: {
      x: 2880,
      y: 2112,
      width: 96,
      height: 96,
    },
    surveycto: {
      x: 2880,
      y: 2208,
      width: 96,
      height: 96,
    },
    surveysparrow: {
      x: 2880,
      y: 2304,
      width: 96,
      height: 96,
    },
    swell_is: {
      x: 2880,
      y: 2400,
      width: 96,
      height: 96,
    },
    synap: {
      x: 2880,
      y: 2496,
      width: 96,
      height: 96,
    },
    syncro: {
      x: 2880,
      y: 2592,
      width: 96,
      height: 96,
    },
    synthesia: {
      x: 2880,
      y: 2688,
      width: 96,
      height: 96,
    },
    tableau: {
      x: 2880,
      y: 2784,
      width: 96,
      height: 96,
    },
    tai_software: {
      x: 0,
      y: 2880,
      width: 96,
      height: 96,
    },
    talentlyft: {
      x: 96,
      y: 2880,
      width: 96,
      height: 96,
    },
    talkable: {
      x: 192,
      y: 2880,
      width: 96,
      height: 96,
    },
    talon_one: {
      x: 288,
      y: 2880,
      width: 96,
      height: 96,
    },
    tanda: {
      x: 384,
      y: 2880,
      width: 96,
      height: 96,
    },
    tango: {
      x: 480,
      y: 2880,
      width: 96,
      height: 96,
    },
    tap: {
      x: 576,
      y: 2880,
      width: 96,
      height: 96,
    },
    tapfiliate: {
      x: 672,
      y: 2880,
      width: 96,
      height: 96,
    },
    targetprocess: {
      x: 768,
      y: 2880,
      width: 96,
      height: 96,
    },
    tatum_io: {
      x: 864,
      y: 2880,
      width: 96,
      height: 96,
    },
    taxamo: {
      x: 960,
      y: 2880,
      width: 96,
      height: 96,
    },
    taxjar: {
      x: 1056,
      y: 2880,
      width: 96,
      height: 96,
    },
    teachable: {
      x: 1152,
      y: 2880,
      width: 96,
      height: 96,
    },
    teamup: {
      x: 1248,
      y: 2880,
      width: 96,
      height: 96,
    },
    telnyx: {
      x: 1344,
      y: 2880,
      width: 96,
      height: 96,
    },
    temi: {
      x: 1440,
      y: 2880,
      width: 96,
      height: 96,
    },
    tettra: {
      x: 1536,
      y: 2880,
      width: 96,
      height: 96,
    },
    textmagic: {
      x: 1632,
      y: 2880,
      width: 96,
      height: 96,
    },
    thanx: {
      x: 1728,
      y: 2880,
      width: 96,
      height: 96,
    },
    the_trade_desk_delta: {
      x: 1824,
      y: 2880,
      width: 96,
      height: 96,
    },
    the_trade_desk_graphql: {
      x: 1920,
      y: 2880,
      width: 96,
      height: 96,
    },
    thought_industries: {
      x: 1056,
      y: 3168,
      width: 96,
      height: 32,
    },
    thrivecart: {
      x: 3168,
      y: 2342,
      width: 96,
      height: 50,
    },
    ticketsignup: {
      x: 2016,
      y: 2880,
      width: 96,
      height: 96,
    },
    tidycal: {
      x: 2112,
      y: 2880,
      width: 96,
      height: 96,
    },
    tiktok: {
      x: 2208,
      y: 2880,
      width: 96,
      height: 96,
    },
    tilaa: {
      x: 2304,
      y: 2880,
      width: 96,
      height: 96,
    },
    timecamp: {
      x: 2400,
      y: 2880,
      width: 96,
      height: 96,
    },
    tines: {
      x: 2496,
      y: 2880,
      width: 96,
      height: 96,
    },
    tink: {
      x: 2592,
      y: 2880,
      width: 96,
      height: 96,
    },
    todoist: {
      x: 2688,
      y: 2880,
      width: 96,
      height: 96,
    },
    token_metrics: {
      x: 2784,
      y: 2880,
      width: 96,
      height: 96,
    },
    totango: {
      x: 2880,
      y: 2880,
      width: 96,
      height: 96,
    },
    tovuti: {
      x: 2976,
      y: 0,
      width: 96,
      height: 96,
    },
    trackdesk: {
      x: 2976,
      y: 96,
      width: 96,
      height: 96,
    },
    trade_gov: {
      x: 2976,
      y: 192,
      width: 96,
      height: 96,
    },
    traffit: {
      x: 2976,
      y: 288,
      width: 96,
      height: 96,
    },
    traject: {
      x: 2976,
      y: 384,
      width: 96,
      height: 96,
    },
    trakstar: {
      x: 2976,
      y: 480,
      width: 96,
      height: 96,
    },
    transifex: {
      x: 2976,
      y: 576,
      width: 96,
      height: 96,
    },
    transkriptor: {
      x: 2976,
      y: 672,
      width: 96,
      height: 96,
    },
    trax: {
      x: 2976,
      y: 768,
      width: 96,
      height: 96,
    },
    treasury_gov: {
      x: 2976,
      y: 864,
      width: 96,
      height: 96,
    },
    tree_nation: {
      x: 2976,
      y: 960,
      width: 96,
      height: 96,
    },
    trengo: {
      x: 2976,
      y: 1056,
      width: 96,
      height: 96,
    },
    trestle: {
      x: 2976,
      y: 1152,
      width: 96,
      height: 96,
    },
    trinet: {
      x: 2976,
      y: 1248,
      width: 96,
      height: 96,
    },
    trint: {
      x: 2976,
      y: 1344,
      width: 96,
      height: 96,
    },
    tripleseat: {
      x: 2976,
      y: 1440,
      width: 96,
      height: 96,
    },
    trumpia: {
      x: 2976,
      y: 1536,
      width: 96,
      height: 96,
    },
    trustradius: {
      x: 2976,
      y: 1632,
      width: 96,
      height: 96,
    },
    tune: {
      x: 2976,
      y: 1728,
      width: 96,
      height: 96,
    },
    twitch: {
      x: 3168,
      y: 1073,
      width: 96,
      height: 73,
    },
    uber_eats: {
      x: 2976,
      y: 1824,
      width: 96,
      height: 96,
    },
    uberall: {
      x: 2976,
      y: 1920,
      width: 96,
      height: 96,
    },
    uberflip: {
      x: 2976,
      y: 2016,
      width: 96,
      height: 96,
    },
    uk_environment_agency_flood_monitoring: {
      x: 2976,
      y: 2112,
      width: 96,
      height: 96,
    },
    ukg_pro_learning: {
      x: 2976,
      y: 2208,
      width: 96,
      height: 96,
    },
    ukg_pro_workforce: {
      x: 2976,
      y: 2304,
      width: 96,
      height: 96,
    },
    ukg_ready: {
      x: 2976,
      y: 2400,
      width: 96,
      height: 96,
    },
    unanet: {
      x: 2976,
      y: 2496,
      width: 96,
      height: 96,
    },
    unanet_cosential: {
      x: 2976,
      y: 2592,
      width: 96,
      height: 96,
    },
    unbounce: {
      x: 2592,
      y: 3206,
      width: 96,
      height: 19,
    },
    unione: {
      x: 2976,
      y: 2688,
      width: 96,
      height: 96,
    },
    universe: {
      x: 2976,
      y: 2784,
      width: 96,
      height: 96,
    },
    updown: {
      x: 2976,
      y: 2880,
      width: 96,
      height: 96,
    },
    upguard_cyberrisk: {
      x: 0,
      y: 2976,
      width: 96,
      height: 96,
    },
    uphance: {
      x: 96,
      y: 2976,
      width: 96,
      height: 96,
    },
    uplead: {
      x: 192,
      y: 2976,
      width: 96,
      height: 96,
    },
    uptime: {
      x: 288,
      y: 2976,
      width: 96,
      height: 96,
    },
    upwave: {
      x: 384,
      y: 2976,
      width: 96,
      height: 96,
    },
    us_census: {
      x: 480,
      y: 2976,
      width: 96,
      height: 96,
    },
    usajobs: {
      x: 576,
      y: 2976,
      width: 96,
      height: 96,
    },
    user: {
      x: 672,
      y: 2976,
      width: 96,
      height: 96,
    },
    userflow: {
      x: 768,
      y: 2976,
      width: 96,
      height: 96,
    },
    useriq: {
      x: 864,
      y: 2976,
      width: 96,
      height: 96,
    },
    usersnap: {
      x: 960,
      y: 2976,
      width: 96,
      height: 96,
    },
    usgs_earthquake_catalog: {
      x: 1056,
      y: 2976,
      width: 96,
      height: 96,
    },
    uxcam: {
      x: 1152,
      y: 2976,
      width: 96,
      height: 96,
    },
    uxsniff: {
      x: 1248,
      y: 2976,
      width: 96,
      height: 96,
    },
    vbout: {
      x: 1344,
      y: 2976,
      width: 96,
      height: 96,
    },
    vcita: {
      x: 1440,
      y: 2976,
      width: 96,
      height: 96,
    },
    vectara: {
      x: 1536,
      y: 2976,
      width: 96,
      height: 96,
    },
    venly: {
      x: 1632,
      y: 2976,
      width: 96,
      height: 96,
    },
    versapay: {
      x: 1728,
      y: 2976,
      width: 96,
      height: 96,
    },
    vestaboard: {
      x: 1824,
      y: 2976,
      width: 96,
      height: 96,
    },
    vetspire: {
      x: 1920,
      y: 2976,
      width: 96,
      height: 96,
    },
    victorops: {
      x: 2016,
      y: 2976,
      width: 96,
      height: 96,
    },
    vidyard: {
      x: 3168,
      y: 2392,
      width: 96,
      height: 50,
    },
    viewpoint: {
      x: 2112,
      y: 2976,
      width: 96,
      height: 96,
    },
    vindicia: {
      x: 2208,
      y: 2976,
      width: 96,
      height: 96,
    },
    viral_loops: {
      x: 2304,
      y: 2976,
      width: 96,
      height: 96,
    },
    viralsweep: {
      x: 2400,
      y: 2976,
      width: 96,
      height: 96,
    },
    virtual_incentives: {
      x: 2496,
      y: 2976,
      width: 96,
      height: 96,
    },
    virtualbadge_io: {
      x: 2592,
      y: 2976,
      width: 96,
      height: 96,
    },
    virtuous: {
      x: 2688,
      y: 2976,
      width: 96,
      height: 96,
    },
    virustotal: {
      x: 2784,
      y: 2976,
      width: 96,
      height: 96,
    },
    vivocalendar: {
      x: 1920,
      y: 3168,
      width: 96,
      height: 27,
    },
    voiso: {
      x: 2880,
      y: 2976,
      width: 96,
      height: 96,
    },
    volunteerhub: {
      x: 2976,
      y: 2976,
      width: 96,
      height: 96,
    },
    vomo: {
      x: 3072,
      y: 0,
      width: 96,
      height: 96,
    },
    vouchfor: {
      x: 3072,
      y: 96,
      width: 96,
      height: 96,
    },
    vtiger: {
      x: 3072,
      y: 192,
      width: 96,
      height: 96,
    },
    vultr: {
      x: 3072,
      y: 288,
      width: 96,
      height: 96,
    },
    wachete: {
      x: 3072,
      y: 384,
      width: 96,
      height: 96,
    },
    waitlist: {
      x: 3072,
      y: 480,
      width: 96,
      height: 96,
    },
    walichat: {
      x: 3072,
      y: 576,
      width: 96,
      height: 96,
    },
    walkme: {
      x: 3072,
      y: 672,
      width: 96,
      height: 96,
    },
    walls_io: {
      x: 3072,
      y: 768,
      width: 96,
      height: 96,
    },
    wanikani: {
      x: 3072,
      y: 864,
      width: 96,
      height: 96,
    },
    watchmode: {
      x: 3072,
      y: 960,
      width: 96,
      height: 96,
    },
    watersignal: {
      x: 3072,
      y: 1056,
      width: 96,
      height: 96,
    },
    wati: {
      x: 3072,
      y: 1152,
      width: 96,
      height: 96,
    },
    waveline_extract: {
      x: 3072,
      y: 1248,
      width: 96,
      height: 96,
    },
    wavely: {
      x: 3072,
      y: 1344,
      width: 96,
      height: 96,
    },
    wealthbox: {
      x: 3072,
      y: 1440,
      width: 96,
      height: 96,
    },
    weather_gov: {
      x: 3072,
      y: 1536,
      width: 96,
      height: 96,
    },
    webex: {
      x: 3072,
      y: 1632,
      width: 96,
      height: 96,
    },
    webflow: {
      x: 3072,
      y: 1728,
      width: 96,
      height: 96,
    },
    webinarfuel: {
      x: 768,
      y: 3230,
      width: 96,
      height: 13,
    },
    webinargeek: {
      x: 3072,
      y: 1824,
      width: 96,
      height: 96,
    },
    whatconverts: {
      x: 3072,
      y: 1920,
      width: 96,
      height: 96,
    },
    whispir: {
      x: 3168,
      y: 2442,
      width: 96,
      height: 50,
    },
    whitehatsec: {
      x: 3072,
      y: 2016,
      width: 96,
      height: 96,
    },
    willo: {
      x: 3072,
      y: 2112,
      width: 96,
      height: 96,
    },
    wise: {
      x: 1152,
      y: 3206,
      width: 96,
      height: 22,
    },
    wisepops: {
      x: 3072,
      y: 2208,
      width: 96,
      height: 96,
    },
    withorb: {
      x: 3072,
      y: 2304,
      width: 96,
      height: 96,
    },
    wonderpush: {
      x: 3072,
      y: 2400,
      width: 96,
      height: 96,
    },
    woodpecker_co: {
      x: 3072,
      y: 2496,
      width: 96,
      height: 96,
    },
    wootric: {
      x: 3072,
      y: 2592,
      width: 96,
      height: 96,
    },
    woovi: {
      x: 3072,
      y: 2688,
      width: 96,
      height: 96,
    },
    workast: {
      x: 3072,
      y: 2784,
      width: 96,
      height: 96,
    },
    workato: {
      x: 3072,
      y: 2880,
      width: 96,
      height: 96,
    },
    workday_raas: {
      x: 3072,
      y: 2976,
      width: 96,
      height: 96,
    },
    workiom: {
      x: 0,
      y: 3072,
      width: 96,
      height: 96,
    },
    workiz: {
      x: 96,
      y: 3072,
      width: 96,
      height: 96,
    },
    workos: {
      x: 192,
      y: 3072,
      width: 96,
      height: 96,
    },
    workstream: {
      x: 3071,
      y: 3072,
      width: 96,
      height: 95,
    },
    worksuite: {
      x: 288,
      y: 3072,
      width: 96,
      height: 96,
    },
    worldbank: {
      x: 3168,
      y: 2492,
      width: 96,
      height: 50,
    },
    wrangle: {
      x: 1632,
      y: 3168,
      width: 96,
      height: 28,
    },
    wufoo: {
      x: 384,
      y: 3072,
      width: 96,
      height: 96,
    },
    wyscout: {
      x: 480,
      y: 3072,
      width: 96,
      height: 96,
    },
    xata: {
      x: 576,
      y: 3072,
      width: 96,
      height: 96,
    },
    xmatters: {
      x: 672,
      y: 3072,
      width: 96,
      height: 96,
    },
    xobin: {
      x: 768,
      y: 3072,
      width: 96,
      height: 96,
    },
    xola: {
      x: 3168,
      y: 190,
      width: 96,
      height: 94,
    },
    xprt: {
      x: 864,
      y: 3072,
      width: 96,
      height: 96,
    },
    xtract: {
      x: 960,
      y: 3072,
      width: 96,
      height: 96,
    },
    yelp: {
      x: 1056,
      y: 3072,
      width: 96,
      height: 96,
    },
    yodiz: {
      x: 1152,
      y: 3072,
      width: 96,
      height: 96,
    },
    yoplanning: {
      x: 1248,
      y: 3072,
      width: 96,
      height: 96,
    },
    yotpo_ugc: {
      x: 1344,
      y: 3072,
      width: 96,
      height: 96,
    },
    younoodle: {
      x: 1440,
      y: 3072,
      width: 96,
      height: 96,
    },
    your_surveys: {
      x: 3168,
      y: 473,
      width: 90,
      height: 96,
    },
    youraspire: {
      x: 1536,
      y: 3072,
      width: 96,
      height: 96,
    },
    yousign: {
      x: 2016,
      y: 3168,
      width: 96,
      height: 27,
    },
    zabbix: {
      x: 1632,
      y: 3072,
      width: 96,
      height: 96,
    },
    zagomail: {
      x: 1728,
      y: 3072,
      width: 96,
      height: 96,
    },
    zakeke: {
      x: 1824,
      y: 3072,
      width: 96,
      height: 96,
    },
    zenedu: {
      x: 1920,
      y: 3072,
      width: 96,
      height: 96,
    },
    zenhub: {
      x: 2016,
      y: 3072,
      width: 96,
      height: 96,
    },
    zenkit: {
      x: 2112,
      y: 3072,
      width: 96,
      height: 96,
    },
    zenloop: {
      x: 2208,
      y: 3072,
      width: 96,
      height: 96,
    },
    zenoti: {
      x: 2112,
      y: 3168,
      width: 96,
      height: 27,
    },
    zesty: {
      x: 2304,
      y: 3072,
      width: 96,
      height: 96,
    },
    zilliz: {
      x: 2400,
      y: 3072,
      width: 96,
      height: 96,
    },
    zinc: {
      x: 2496,
      y: 3072,
      width: 96,
      height: 96,
    },
    zinrelo: {
      x: 2592,
      y: 3072,
      width: 96,
      height: 96,
    },
    zixflow: {
      x: 2688,
      y: 3072,
      width: 96,
      height: 96,
    },
    zohobilling: {
      x: 2784,
      y: 3072,
      width: 96,
      height: 96,
    },
    zulip: {
      x: 2880,
      y: 3072,
      width: 96,
      height: 96,
    },
    zylo: {
      x: 2208,
      y: 3168,
      width: 96,
      height: 27,
    },
  },
};
export default spriteSheet;
